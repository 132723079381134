import React, { useState, useCallback,useReducer,useEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from './Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, type,
  add
} from 'ramda'
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider,Tooltip } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from '../../assets/images/location.png'
import ManIcon from '../../assets/images/regularworker-purple.png'
import CHristMasManIcon from '../../assets/images/dailyworker-yellow.png'

import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import DefaultIcon from  "../../assets/images/locationDefaultIcon.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";
import NewIcon from "../../assets/images/locationLessProductive2.png";
import { getCategoryList} from '../actionMethods/actionMethods'

import {getLocationCordinate} from '../actionMethods/actionMethods';

import { getDepartment,getSubDepartment } from '../../productivity/actionMethods/actionMethods';

import {getPproductivitylist} from '../../productivity/actionMethods/actionMethods'
import {getAllEmpProductivityInfo,getCycleCountPlan,getLiveData,getOnBoardEmployee,getLocationType,getPletonEmployee} from '../actionMethods/actionMethods';
import { getRoleDetail } from '../../common/actionMethods';
import {ReactComponent as Eye} from '../../assets/images/eye.svg';
import ReactModal from 'react-modal';
import { getActiveEmployeeV2 } from '../actionMethods/actionMethods';
import Scrollbars from 'react-custom-scrollbars';

import '../style/style.scss'
import { Select } from 'antd';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg';

import {ReactComponent as PlayCircle} from '../../assets/images/play-circle.svg';
import {ReactComponent as PauseCircle} from '../../assets/images/pause-circle.svg';
import * as d3 from 'd3';
import CMGBulky from '../../assets/cmg_bulky.json'
import CMGManual from '../../assets/cmg_manual.json'
import ReactDOMServer from 'react-dom/server';

// import ManProductiveIcon from '../../assets/images/squareProd.png'
// import ManLessProductiveIcon from '../../assets/images/squareLessProd.png'
// import ManNonProductiveIcon from '../../assets/images/squareNonProd.png'
import DefaultManIcon from '../../assets/images/deafutManIconSmall.png'
import ManProductiveIcon from '../../assets/images/man.png'
import ManLessProductiveIcon from '../../assets/images/dailyworker-yellow.png'
import ManNonProductiveIcon from '../../assets/images/nonProductiveMan.png'


let dataSocket=[];   
let oldLocation=[];
let batch30=[];
let dataRemove =false;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


const mystyle = {
  display: "none !important",
};

let layerPublish=0;
let fetchHit =0;
const FacilityMapPleton = (props) => {

  const { Option } = Select;
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState('3d')

  const [activeTab, SetActiveTab]= useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ]);
  const [color1, setColor1] = useState('#ff0000');

  const [color2, setColor2] = useState('#FBA81F');
  const [color3, setColor3] = useState('#79fc03');

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

    let resetTime= moment().isSame(date, 'day')?30:5;  
    const [live,SetLive]=useState(moment().isSame(date, 'day')?true:false);
  const [nextUpdate,SetNextUpdate] = useState(resetTime);
  const [liveAPI,SetLIveAPI] = useState(false);

    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [locationListDefault,SetLocationListDefault] =  useState([]);
    const [reloadInc,SetReloadInc] = useState(0);
  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({start:date,end:moment.utc()});
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [localTime,SetLocalTime] = useState([]);
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )  
  const [space,setSpace] = useState()
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:(userDetails && parseInt(userDetails.org_id) == 35) || (userDetails && parseInt(userDetails.org_id) == 38)?1: (userDetails && parseInt(userDetails.org_id) == 37)?0.4:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?1.5:1.2;
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let prefixURL = process.env.REACT_APP_URL;
  let userSession = userDetails ? userDetails.session:props.match.params.session?props.match.params.session:'';
  let org_id = userDetails ? userDetails.org_id : props.match.params.org_id?props.match.params.org_id:'';
  const role = props.match.params.role?props.match.params.role:null; 
  const canvasRange = {
    topRight: { x: 91.01915303072015, z: 28.32575004981593 },
    topLeft: { x: -35.2750555742273, z: 23.835903322863807 },
    bottomLeft: { x: -16.32471441542863, z: -51.90536740912293 },
    bottomRight: { x: 91.01915303072015, z: -51.90536740912293 }
  };
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);  
  const [employeeALL,SetEmployeeAll] =useState([]);
  const [employeeDefault,SetEmployeeDefault] =useState([]);
  const [department,SetDeparment] = useState([]);
  const [sub_department,SetSubDeparment] = useState([]);
  const [activeDepartment,SetActiveDepartment] = useState([]);
  const [activeSubDepartment,SetActiveSubDepartment] = useState([]);
  const [apiCall,SetAPICall]=useState(false)
  const [activeAccord,SetActiveAccord] =useState([]);
  const [locationList,SetLocationList] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [slug,SetSlug]=useState('');
  const [modalView,SetModalView]=useState(false);
  const [modalData,SetModalData ]= useState([]);                   
  const [inc,SetInc]=useState(0)
  const [time,SetTime] =useState('');      
  const [delayTimer,SetDelayTimer]= useState(false);
  const [departmentName,SetDepartmentName]=useState([]);
  const [subDepartmentName,SetSubDepartmentName]=useState([]);
  const [playPosition,SetPlayPosition]= useState(0);
  const [currentData,SetCurrentData]= useState([]);

  const onReady = useCallback(space => setSpace(space), [])
  const [timeOptions, setTimeOptions] = useState([]);
  const [startTime,SetStartTime] = useState('');
  const [endTime,SetEndTime] = useState('');
  const [displayTime,SetDisplayTime] = useState('');
  const [autoplay,SetAutoPlay] = useState(true);
  const [hoverValue, setHoverValue] = useState(null);
  const [productiveType,SetProductiveType] = useState([]);
  const [btnChange,SetBtnChange] = useState(false);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

  const [cameraPlacement,SetCameraPlacement]=useState({ 
    beta: 0,
    radius: 150.58138483102492,
    target: {
      x: 30,
      y: 0,
      z: 0
    }
  })
  const [points, dispatchPoint] = useReducer((points, action) => {
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return points.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:
    }
  },[])
  

  useEffect(()=>{
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0});
    
      let dt={start:null,end:null}
      if(timeAndDate <= moment() ){        
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(addDay,'days').toDate()})); 

      }else {  
        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(addDay,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));
      }
       getLocationType(userSession).then(res=>{
        if(res.status===200){
          let obj = {};
          obj.productive= res.data.find(el=>el.loc_type=="productive").id
          obj.less_productive= res.data.find(el=>el.loc_type=="less_productive").id
          obj.non_productive= res.data.find(el=>el.loc_type=="non_productive").id
          SetProductiveType(obj);
        }
      })


    },[])

    useEffect(()=>{
      if(!space){
        console.log("generateTimeOptions(selectedDate)",generateTimeOptions(selectedDate))
        return
      }
      let val= generateTimeOptions(selectedDate);
      setTimeOptions(val.utc);
      SetLocalTime(val.local);


      
      let ind = 2;
        if(val.utc[val.utc.length - ind].split(":")[2]==='30'){
          ind = 3;
        }

      if(moment().utc().format('YYYY-MM-DD') === moment(selectedDate).utc().format('YYYY-MM-DD')){
        

        SetStartTime(val.utc[val.utc.length - ind]);
        SetDisplayTime(val.local[val.local.length - ind]);
        SetPlayPosition((prev)=>{
          return val.utc.length - ind;
        });
        
      }else{

        SetStartTime(val.utc[0] ||  '00:00:00');
        SetPlayPosition(0);

      }
    },[selectedDate,space])


  useEffect(()=>{ 
    
    let requestBody2={};
    requestBody2.session=userSession
    requestBody2.org_id=org_id

    getCategoryList(requestBody2).then(res2=>{
        if(res2.status == 200){
          let arrCat=res2.data;
          let requestBody={}
          requestBody.session= userSession;
          requestBody.org_id= org_id;
          let arr=[];

        for(let i=0;i<res2.data.length;i++){
          if(res2.data[i].location.length > 0){
            let loc = res2.data[i];
            loc.count = res2.data[i].location.length;
            arr.push(loc);
       
          }
        }

        SetLocationList(arr); 
        SetLocationListDefault(arr)
      
        }else{
            SetLocationList([]);                
            SetLocationListDefault([])

        }
    SetLoader(false);
})
},[])  
  
  
  const timerLive = ()=>{
    SetTime(moment().format('HH:mm:ss'));
  }
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }  




      function datetime(year, month, date, hour=0,minute=0,second=0,millisecond=0){
        return 0
      }

 

useEffect(() => {
  if(!space || locationCordinate.length === 0 || permission.length == 0 || startTime === "" || !autoplay || live) {
    return;
  }

    const abortController = new AbortController();
    const { signal } = abortController;

    let start = '';
    let end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');

    if (moment(selectedDate).utc().format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      start = moment(selectedDate).utc().format('YYYY-MM-DD');
      end = '';
    } else {
      start = moment(selectedDate).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD');
      end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');
    }

  
    let st = startTime.split(":");
    let stime = st[0] + ":" + st[1] + ':00';
    let endTime = timeOptions[timeOptions.length - 1]
    console.log("timeOptions",timeOptions,)
    

    const eventSource = new EventSource(`${prefixURL}/recording_data?session=${userDetails.session}&org_id=${userDetails.org_id}&start_time=${startTime}&end_time=${endTime}&sub_org_id=${userDetails.sub_org_id ? userDetails.sub_org_id : 0}`, { signal });

    eventSource.onmessage = async (res) => {
      let newData = JSON.parse(res.data).map(el => ({ ...el, tag_serial: el.tag_id }));
      console.log("new Data",newData,employeeALL);
    
      let data=[];
      for(let i=0;i<newData.length;i++){
        let single = employeeALL.find(el=>newData[i].tag_id == el.tag_serial )
         if(single){data.push(newData[i])}else{};
      }
      dataSocket = data;

      if (layerPublish !== 1) {
        if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){

          if(data.length > 0){
            
              setTimeOptions((tOption)=>{
                var originalTime = tOption[tOption.length - 1];
                var date = new Date('2023-01-01 ' + originalTime);
                date.setSeconds(date.getSeconds() + 30);
                var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
              
                var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
                var localDate = moment(utcDate).local();
                let index = tOption.indexOf(moment(data[0].contact_time).subtract(7,'hours').format('HH:mm:ss'));
                if(index > -1){
                  SetPlayPosition(index);
                  return tOption
                }else{
                                    
                  SetLocalTime((prev)=>[...prev,localDate.add(30, 'seconds').format('HH:mm:ss')]); 
                  SetPlayPosition((prev)=>prev + 1);
                  return ([...tOption,updatedTime])
                }
              })

              
          }

        }else{
          SetPlayPosition((prev) => prev + 1);
        }
      }

      
    processFacilityMap(data);


    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
     
    };

    return () => {
      abortController.abort();

      eventSource.close();
    };

}, [space, permission, locationCordinate,timeOptions, selectedDate, startTime, autoplay,employeeALL,productiveType,live]);

function processFacilityMap(data){
        if (data.length > 0) {
        layerPublish++;
        SetDelayTimer(false);
        SetCurrentData(data);
        SetNextUpdate(30);
        SetLIveAPI(true);
        let productive=[]
        let lessproductive=[]
        let nonproductive=[]
        let defaultProducive= []
        addPointLessProductive([])
        addPointProductive([])
        addPointNonProductive([])
        addPointDeafult([]);
        for(let i=0;i<dataSocket.length;i++){

        let mm = [...employeeALL].find(item=>item.tag_serial == dataSocket[i].tag_id);
        
          if(mm){
          let formatAr={}
          let pos = locationCordinate.find(el=>el.tag_serial === dataSocket[i].loc_id);

          
          if(!pos){
           
          }else{
          formatAr.name=mm?.name
          let location = pos;
          formatAr.id= randomString(32);
          formatAr.tag_serial =dataSocket[i].tag_serial;          
          //removing location position
          if(parseInt(org_id) == 32){
            formatAr.position = {
              "levelIndex": pos?pos.position.levelIndex:0,
              "x": pos.position.x + GetRandomDigit(-3.5,3.5),
              "z": pos.position.z + GetRandomDigit(-5,-0.5),
              "elevation": parseInt(org_id) == 37?1:2.2
            }
          }else if(parseInt(org_id) == 33){
            formatAr.position = {
              "levelIndex": pos?pos.position.levelIndex:0,
              "x": pos.position.x + GetRandomDigit(-2.5,3.0),
              "z": pos.position.z + GetRandomDigit(-4.5,-0.1),
              "elevation": parseInt(org_id) == 37?1:2.2
            }
           } else{
            formatAr.position = {
              "levelIndex": pos?pos.position.levelIndex:0,
              "x": pos.position.x +  GetRandomDigit(-6,6),
              "z": pos.position.z + GetRandomDigit(-10,-1),
              "elevation": parseInt(org_id) == 37?1:2.2
           }
          }
 
          // formatAr.position = {
          //   "levelIndex": pos?pos.position.levelIndex:0,
          //   "x": dataSocket[i].x,
          //   "z": dataSocket[i].z,
          //   "elevation": parseInt(org_id) == 37?1:2.2
          // }
          
          formatAr.time= moment().unix(); 
          formatAr.tag_serial= location.tag_serial;
          formatAr.loc_id= location.tag_serial;       

          if(parseInt(dataSocket[i].productivity)=== productiveType['productive']){
            productive.push(formatAr);

          }else if(parseInt(dataSocket[i].productivity)=== productiveType['non_productive']){
              nonproductive.push(formatAr);
          }else if(parseInt(dataSocket[i].productivity)=== productiveType['less_productive']){
            lessproductive.push(formatAr);
          }else{
              defaultProducive.push(formatAr)
          }
          
          }

        }else{
          console.log("employee skipped",dataSocket[i].tag_id)
        }


        }
        
        addPointLessProductive(lessproductive)
        addPointProductive(productive)
        addPointNonProductive(nonproductive);
        addPointDeafult(defaultProducive)
        
      }
}

 function addPointDeafult(points){

      if(space){
      space.addDataLayer({
        id: 'points4',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:DefaultManIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,
      })
    }

  }


    useEffect(() => {
      let pagePerm= props.pages.filter(el=>el.page_name=="Facility Map Pleton")
      
      SetPermission(pagePerm);
      SetPermissionAPICall(true)
      
    },[props.pages]);

  function AccordionHandler(val,type='secondary'){
    let ActivList = [...activeAccord];
    let index = ActivList.indexOf(val)
    if(index !== -1){  
        ActivList.splice(index, 1);
        SetActiveAccord(ActivList);      
    }
    else if(type=='primary'){
        SetActiveAccord([val]);
 }     
    else {  
        ActivList.push(val);
        SetActiveAccord(ActivList);      
    }
    
}

  useEffect(()=>{
    if(permission.length > 0){
      let geoLocation=[];
      getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){

        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }
                  if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                    SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
                    for(let i=0;i<CMGBulky.length;i++){
                      let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
                     
                      if(dataFind){
                        
                        geoLocation.push(dataFind);
                      }

                    }
                  }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                      SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
                      for(let i=0;i<CMGManual.length;i++){

                      let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
                      if(dataFind){
                        
                        geoLocation.push(dataFind);
                      }
                    }

                  }else{
                    SetSpaceID(res.data.space_id);

                    for(let i=0;i<res.data.location.length;i++){
                      let d =generatePath(Math.random()*10,Math.random()*10);
                      geoLocation.push({...res.data.location[i],d});
                    }
                    
                  
                  }

                  SetLocationCordinate(geoLocation);
          
 
          getCycleCountPlan(userSession,org_id,moment().utc().format('YYYY-MM-DD')).then(res2=>{
              if(res2.status==200){
                  
                for(let i=0;i<res.data.location.length;i++){
                    let ctPlan = moment().format('HH');
              
                      let data = res2.data[res.data.location[i].tag_serial]
                      data =  data?data.find(item=>item.hour == ctPlan+":00"):undefined; 
                      let result =0;
                      if(data){
                          res.data.location[i].planned = Math.ceil(data.cycle_count);
                      }else{
                        res.data.location[i].planned = '-';
                      }
                   
                  }
                  

              }else{
               
                for(let i=0;i<res.data.location.length;i++){
                    res.data.location[i].planned = '-';
                  }
                  
                  SetLocationCordinate(res.data.location);
              }
            })
        }
      })

      getDepartment(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          SetDeparment(res.data);  
        }
      });
      
      SetLoaderEmp(true);
      
    }

  },[permission])   


useEffect(()=>{
    if(permission.length > 0){
    addPointProductive([])
    addPointLessProductive([]);
    addPointNonProductive([]);

    let empList =[];
       let requestBody={}
    requestBody.start_date= moment(selectedDate).subtract(addDay,'days').format('YYYY-MM-DD')
    requestBody.end_date=   moment(selectedDate).format('YYYY-MM-DD')    
    requestBody.slug= 'employee';
    let dept =[];
    

      getPletonEmployee(userSession,org_id,getDateFormat(selectedDate)).then(res3=>{
          console.log(res3.data);
          
        if(res3.status === 200){

          let pletonEmp=  res3.data;
          const uniquePleton = [...new Set(res3.data.map(item => item.pleton))];
          SetDeparment(uniquePleton);




     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
      
      if(res.status==200){
        if(res.data.daily){
          let empDaily = res.data.daily.filter(el=>pletonEmp.findIndex(pl=>pl.tag_serial  === el.tag_serial && pl.name=== el.name )  > -1
          && empList.findIndex(emp=> emp.tag_serial === el.tag_serial) === -1
        );
          
          empDaily = empDaily.map(el=>({
            ...el,
            emp_name:el.name,
            dept_name: el.department_name,
            sub_dept_name: el.sub_department_name,
            sub_department: el.sub_department_name,         
            emp_type: "daily_worker",
            pleton:pletonEmp.find(p=>p.tag_serial === el.tag_serial && p.name === el.name)?.pleton
          }))

          for(let  i = 0;i<empDaily.length;i++){
            if(empList.findIndex(el=>el.tag_serial === empDaily[i].tag_serial && el.name === empDaily[i].name) === -1){
              empList.push(empDaily[i]);
            }
          }

          console.log("pleton Daily worker",empDaily)
        }


                if(res.data.regular){
                  let empReg = res.data.regular.filter(el=>pletonEmp.findIndex(pl=>pl.tag_serial  === el.tag_serial && pl.name=== el.name )  > -1 
                && empList.findIndex(emp=> emp.tag_serial === el.tag_serial) === -1
              )
          
          empReg = empReg.map(el=>({
            ...el,
            emp_name:el.name,
            dept_name: el.department_name,
            sub_dept_name: el.sub_department_name,
            sub_department: el.sub_department_name,         
            emp_type: "regular",
            pleton:pletonEmp.find(p=>p.tag_serial === el.tag_serial && p.name === el.name)?.pleton

          }))
          for(let  i = 0;i<empReg.length;i++){
            if(empList.findIndex(el=>el.tag_serial === empReg[i].tag_serial && el.name === empReg[i].name) === -1){
              empList.push(empReg[i]);
            }
          }

          console.log("pleton Regular worker",empReg)
       
      }

      empList = empList.sort((a, b) => new Date(b.check_in) - new Date(a.check_in));
      getActiveEmployeeV2(userSession,org_id,moment(selectedDate).subtract(1,'days').format('YYYY-MM-DD')).then(res2=>{
          console.log("empList",empList);
          if(res2.status===200){
            res2.data= res2.data.map(el=>({...el,department:el.department_name,sub_department:el.sub_department_name}))
            console.log("getActiveEmployeeV2",res2.data);
            for(let i= 0;i<res2.data.length;i++){
              if(empList.findIndex(el=>el.tag_serial === res2.data[i].tag_serial) === -1 && pletonEmp.findIndex(el=> el.tag_serial===res2.data[i].tag_serial) > -1){

                empList.push({...res2.data[i],pleton:pletonEmp.find(p=>p.tag_serial === res2.data[i].tag_serial && p.name === res2.data[i].name)?.pleton})
              }
            }
          }
            console.log("empList pleton",empList,pletonEmp)
          })
     
        SetEmployeeAll(empList);
        SetEmployeeDefault(empList)
        SetLoaderEmp(false);
      }
     })

        }
        
      })





    }
},[permission,selectedDate])





function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}


useEffect(()=>{

  SetAPICall(false)
  removeFrame()

},[employeeALL])



  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }



  useEffect(()=>{
      if(permission.length > 0){

        SetTime(moment().format('HH:mm'));
      if (!space) {
        return
      }

    }

  

    },[space,permission])
    
    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
    function handleDateSelect(date) {
      setDates((prev)=>({...prev,['start']:date}))
    updateSelectedDate(date);
    addPointLessProductive([])
    addPointProductive([])
    addPointNonProductive([])
    layerPublish++;


    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
   
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function addPointProductive(points){
    if(space){
      
      space.addDataLayer({
        id: 'points',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name ,
      })
    }

  }

   function addPointLessProductive(points){

    if(space){
      space.addDataLayer({
        id: 'points2',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManLessProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,
      })
    }

  }
  
  
     function addPointNonProductive(points){

    if(space){
      space.addDataLayer({
        id: 'points3',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManNonProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,
      })
    }

  }
  


  const removeFrame = () => {
    if(!space){
      return
    }
    let data=[...dataSocket];

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
             dispatchPoint({ type: 'remove', id: dt.id });
              
              dataSocket.splice(i,1)
          }
          
         }
        dataSocket=[];

  }
  
  function updateDataLayer(data,url){


    if(space){

    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: 96,
          height:96
        },
        width: GeoWidth,
        anchor: 'top',
        tooltip: data => data.name,
      })    
    }
  }

  }


  function AddDataLayer(data){
    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: data.width,
        height: data.width
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.name,
    })
  }
}

useEffect(() => {
  if(!space){
    return 
  }
  let cinc=0;

  const id3 = setInterval(timerLive, 1000);

  const id4 =setInterval(function(){
      SetNextUpdate((prev)=>{
          let val=prev
          if(prev == 0){
            cinc++;
            val= 0;
          }else{
            cinc++;
            val = prev - 1
          }
          return val;
        })
  },1000)
  

return () =>{
  clearInterval(id3);
  clearInterval(id4);
  }   
},[space,employeeALL,selectedDate,playPosition]);

useEffect(()=>{
  if(!space){
    return
  }
    const tOpt = setInterval( function(){
      pushLastSecond(timeOptions,selectedDate)
  },30000);
return () =>{
  clearInterval(tOpt)
  } 
},[space,timeOptions])


function pushLastSecond(timeOptions,selectedDate){
      if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){
          let tOption= [...timeOptions];
        
          var originalTime = tOption[tOption.length - 1];
          var date = new Date('2023-01-01 ' + originalTime);
          date.setSeconds(date.getSeconds() + 30);
          var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
          tOption.push(updatedTime)
          
          var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
          var localDate = moment(utcDate).local();
        
        }
}

    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }

    function ModalFormatData(hourData,tag_serial){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatData"+tag_serial.toLowerCase()}> 
         <h4>{tag_serial.toLowerCase()} {displayTime}  <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];      
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );
        if(emp){
          inner.push(emp)   
        }
      
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
    }

    function ModalFormatcategory(hourData,categoryname){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatcategory"+categoryname.toLowerCase()}> 
         <h4>{categoryname.toLowerCase()}: {displayTime} <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   
       
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_id );

        if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      

    }


    function ModalFormatLocation(hourData){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatLocationTitle"}> 
         <h4>Total employees: {displayTime}<button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   

      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );

      if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      

    }

    function onChangeDepartment(val){
    let nameVal=[]
      dataSocket=[]
      
      if(val.indexOf('all')> -1){
          let value =[];

          for(let i=0;i<department.length;i++){
            value.push(department[i])
            nameVal.push(department[i])
          }
          if((val.length -1) == value.length ){
            SetActiveDepartment([]);  
            SetDepartmentName([])
          }else{
            SetDepartmentName(nameVal)
            SetActiveDepartment(value);
            
          }
    
          
      }else{
        
        
        SetDepartmentName(val)
        SetActiveDepartment(val);
      }
      SetBtnChange(true);


    }
        function onChangeSubDepartment(val){
      
        dataSocket=[]
    let nameVal=[]
      if(val.indexOf('all')> -1){
        let value =[];
        for(let i=0;i<sub_department.length;i++){
          value.push(sub_department[i].name)
          nameVal.push(sub_department[i].name)
        }
        if((val.length -1) == value.length ){
        
          SetSubDepartmentName([])                    
          SetActiveSubDepartment([]);  
        }else{
        
          SetSubDepartmentName(nameVal)
          SetActiveSubDepartment(value);

        }

    
        
    }else{
      

      
       SetSubDepartmentName(val)
      SetActiveSubDepartment(val);
    }

    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }

    function colsValueFormatter(params){
      var eGui = document.createElement('div');
        
      eGui.innerHTML = '<div><div class="titleFont">'+params.data.emp_name+'</div> <span class="smallerfont smallerfontDepartment">'+params.data.dept_name+'</span> <span class="smallerfont">'+params.data.sub_dept_name+'</span> </div>';
                     return eGui;          
      
    }

    function AGTable(data){

    
      if(data  && window.innerWidth > 767){
        
        let widthCal= 0;
    
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100 - 205)/4) ;
        }else{
          widthCal=(( (window.innerWidth * 80)/100) - 205)/4 ;
        }
        let arr;

        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'emp_name',
      
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
             
              rowData={data}
              key={'keyAGTable'}
                onCellClicked={ (event) =>
              {
               
                if(event.data.emp_type == 'daily_worker'){
                  window.open(`/productivity/daily_worker/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }else{
                  window.open(`/productivity/employee/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }
              }
                }

              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
  
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
                      <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="dept_name" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_dept_name"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_dept_name"
             headerName="View"
             width={80}
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else if(data  && window.innerWidth < 767){
      let widthCal= 0;
    
        widthCal=window.innerWidth - 200/1 ;
      let arr;

      arr=<div className={"keyAGTable keyAGTableResponsiveBiggerHeight"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
        <AgGridReact
            rowHeight={45}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
  
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
           
            rowData={data}
            key={'keyAGTable'}
            onCellClicked={ (event) =>
              {
            
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")

                }
              }
                }
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >
          <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>params.value.toLowerCase()}
            cellRenderer= {(params)=>colsValueFormatter(params)}
            cellClass={'textCapitalize textCap'}
        />
        <AgGridColumn
           field="sub_dept_name"
           headerName="View"
           width={80}
           cellClass={'textCapitalize textCap'}
           cellRenderer= {(params)=>viewIconRenderer(params)} >
          </AgGridColumn> 
    </AgGridReact>
    </div></div>
    
    return arr
    }else{
      return ;
    }
    
    }

    function modalViewShow(loc,playPosition){

      let data = currentData.filter(item=>item.loc_id==loc.tag_serial);
      let arr =[];
      arr = ModalFormatData(data,loc.location_name);
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
     SetDisplayTime(localTime[playPosition]);
     SetModalData(arr);
     SetModalView(true)
     
    }

    function categorymodalview(Category_data,playPosition){
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      let arr =[];
      for(let i=0;i<Category_data.location.length;i++){
        
        let data = [...currentData].filter(item=>item.loc_id == Category_data.location[i].tag_serial);
       
        for(let j=0;j<data.length;j++){
          arr = [...arr, data[j]]
        } 
      }
      let arr1=[]
     arr1 = ModalFormatcategory(arr,Category_data.name);  
     SetDisplayTime(localTime[playPosition]);
       SetModalData(arr1);
       SetModalView(true)
    }


    function categorymodalview1(location,playPosition) {

           SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      const arr = location.reduce((acc, loc) => {
        const data = currentData.filter(item => item.loc_id === loc.tag_serial);
        return [...acc, ...data];
      }, []);
    
      const arr1 = ModalFormatLocation(arr);
      SetDisplayTime(localTime[playPosition]);
      SetModalData(arr1);
      SetModalView(true);
    }

     function changeGeoMapSpace(val){

      space.remove();
      SetSpaceID(val);



     }
     function locNameRender(params){
  
      var op =document.createElement('div');

      var eGui = document.createElement('div');
    
      eGui.innerHTML= '<div class="elementCell textCap">'+params.value.toLowerCase()+'</div>';

return eGui;

          
  }

  function ShowCardListLocation(){
    let data =[...locationList];   
    let arr=[];

    if(data.length>0){
      for (let i = 0; i < data.length; i++) {
      
        let index = i + 1;
       
              const plannedCount = data[i].location.reduce((acc, loc) => {

                const locData = locationCordinate.find(
                  (cord) => cord.tag_serial === loc.tag_serial && cord.name === loc.location_name
                );

                const planned = locData && locData.planned  && locData.planned!='-'? parseInt(locData.planned) : 0;
                return acc + planned;
              }, 0);

              const validPlannedCount = isNaN(plannedCount) ? 0 : plannedCount;

              arr.push(                 
          
                <Row key={"contentCategory" + data[i].name.toLowerCase()}className={`contentGroup${activeAccord.indexOf(data[i].name) !== -1 ? ' active' : ''}${data[i].location && data[i].location.length > 0 ? ' contentCategory' : ''}`}>
                  <div className={`Category-header group-header`}> 
                            <Col lg={3} className={`align-middle align-wrapper`}>
           
            {data[i].location && data[i].location.length > 0 && (
              <span onClick={() => AccordionHandler(data[i].name, 'primary')}>
                {activeAccord.indexOf(data[i].name) !== -1 ? (
                  <CircleArrowDown style={{ height: 15 }} />
                ) : (
                  <CircleArrow style={{ height: 15}} />
                )}
              </span>
            )}
           
            {data[i].location && data[i].location.length > 0 && (
              <h4 style={{ fontSize: "12px",color:"#908fab", margin: 0, marginLeft: 8 }}>
                {data[i].name.charAt(0).toUpperCase() + data[i].name.slice(1)}
              </h4>
            )}
          </Col>


              <Col lg={2} className="align-middle" style={{  fontSize: "9px",marginRight:30 }}>
              <span style={{ fontSize: "10px",color:"#908fab"}}>{data[i].location.length > 0 && validPlannedCount}</span>
            </Col>
          <Col lg={2} className="align-middle" style={{ textAlign: "center", fontSize: "10px",color:"#908fab" }}>
        {data[i].location.length > 0 && (
          <>
            <span style={{ fontSize: "10px", fontWeight: "bold" }}></span>
            {data[i].location.reduce((acc, loc) => {
              
              const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;

              return acc + actual;
            }, 0)}
          </>
        )}
      </Col>

            <Col lg={3} className="align-middle" style={{textAlign:"center",fontSize:"10px"}}>
            {data[i].location.length > 0 ? (
                data[i].location.reduce((acc, loc) => {
                  
                  const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
                  return acc + actual;
                }, 0) > 0 ? (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd" onClick={() => categorymodalview(data[i],playPosition)}>
                      <Eye style={{ height: 13, cursor: "pointer" }} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd disabled nonCLickable">
                      <Eye style={{ height: 13 }} />
                    </div>
                  </React.Fragment>
                )
              ) : null
            }

    </Col>
            </div>
            <div  className={activeAccord.indexOf(data[i].name)!==-1 ?'Category-content active':'Category-content'}>
              <div className="ag-theme-alpine" style={{  width: '100%'}}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    {/* Header cells */}
                  </tr>
                </thead>
                <tbody>
                  {data[i].location.map((loc) => {
                  const locData = locationCordinate.find(
                    (cord) => cord.tag_serial === loc.tag_serial && cord.name === loc.location_name
                  );
                  const planned = locData?.planned || "0";
                    const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
                    const eye =
                      currentData.filter(el=>el.loc_id == loc.tag_serial).length > 0 ? (
                        <div className="legendRightAreaProd" onClick={() => modalViewShow(loc,playPosition)}>
                          <Eye style={{ height: 13, cursor: "pointer",marginLeft:23}} />
                        </div>
                      ) : (
                        <div className="legendRightAreaProd disabled nonCLickable">
                          <Eye style={{ height: 13 ,marginLeft:23}} />
                        </div>
                      );
                    return (
                     <tr key={loc.tag_serial} style={{}}>
                      <td className="locationName">{loc.location_name}</td>
                      <td style={{ padding: 3, fontSize: "10px", color: planned <= actual ? "black" : "grey", width: "20%", textAlign: "right", }}>
                        {planned}
                      </td>
                      <td style={{ padding: 3, fontSize: "10px", color: actual <= planned ? "black" : "grey", width: "20%", textAlign: "right" }}>
                        {actual}
                      </td>
                      <td style={{ width: "30%"}}>{eye}</td>
                    </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>                 
            </div>
          </Row>
        )
      }
    }
    return arr;
  }
  

  function generateSVGHeatmap(angle,curve,value,d){

    return <svg
        id="sw-js-blob-svg"
        viewBox="0 0 100 100"
        width={120}
        height={120}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <radialGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
            {updateGradientReturn(value,0,100)}            
          </radialGradient>
        </defs>
        <path
          fill={`url(#sw-gradient)`}
          d={d?d:generatePath(angle, curve)}
          width="100%"
          height="100%"
          transform="translate(50 50)"
          style={{ transition: 'all 0.3s ease 0s' }}
          strokeWidth="0"
        ></path>
      </svg>
  }

  const updateGradientReturn = (r,g,b) => {

    r= isNaN(r)?0:r;
    g=isNaN(g)?0:g;
    b=isNaN(b)?0:b;
 const gradient = <>
                    <stop id="stop1" stopColor={color1} offset={r+"%"}></stop>
                    <stop id="stop2" stopColor={color2} offset={(r + g)+"%"}></stop>
                    <stop id="stop3" stopColor={color3} offset={(r + g+ b)+"%"}></stop>
                  </>
  return gradient;
};
  const generatePath = (angle, curve) => {
    const initialData = generateData(angle, curve);
    return roundPath(generateBlobShape(initialData) + 'Z');
  };
    const generateData = (complexity, contrast) => {
    const scale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([50 - (50 / 12) * contrast - 0.01, 50]);
    return d3.range(complexity).map(() => scale(Math.random()));
  };
  const roundPath = (path) => {

    return path;
  };

    const generateBlobShape = (data) => {
    const shapeGenerator = d3
      .radialLine()
      .angle((d, i) => (i / data.length) * 2 * Math.PI)
      .curve(d3.curveBasisClosed)
      .radius((d) => d);
    return shapeGenerator(data.map((d) => Math.abs(d)));
  };

  async function svgToDataURL(svgString,where) {  

    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function () {
          canvas.width = 120;
          canvas.height = 120;

          ctx.drawImage(img, 0, 0);

          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
 
        };

        img.src = 'data:image/svg+xml,' + encodeURIComponent(svgString);
      });
  };

const generateTimeOptions = (date) => {
  const utcTimeOptions = [];
  const localTimeOptions = [];
  
  const currentDate = moment().utc();
  const inputDate = moment(date).utc();
  let workday = props.workday
  let start = props.workday.start_hour.split(":")
  let end = props.workday.end_hour.split(":")
  
  let subtract =0;
  if(props.workday.same_day	 ==="no"){
    subtract =1;
  }

  let startOfDay = moment(date).subtract(1).set({hour:start[0],minute:start[1],second:start[3]}).utc();
  let endOfDay = moment(date).set({hour:end[0],minute:end[1],second:end[3]}).utc();

  if(startOfDay > endOfDay) {
        endOfDay = endOfDay.add(1,'day');
  }


  console.log("startOfDay",moment(startOfDay).format('YYYY-MM-DD HH:mm:ss'));
  console.log("endOfDay",moment(endOfDay).format('YYYY-MM-DD HH:mm:ss'));
  const currentHour = currentDate.hour();

  let endTime;

  if (inputDate.isSame(currentDate, 'day')) {
    endTime = moment(currentDate);
  } else if (inputDate.isBefore(currentDate, 'day')) {

    endTime = endOfDay;
  } else {

    endTime = endOfDay;
  }

  let currentTime = startOfDay;

  while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {

    utcTimeOptions.push(currentTime.format('YYYY-MM-DD HH:mm:ss'));
    
    const localTime = moment.utc(currentTime).local();
    localTimeOptions.push(localTime.format('YYYY-MM-DD HH:mm:ss'));
    currentTime = currentTime.add(30, 'seconds');
  }

  return { utc: utcTimeOptions, local: localTimeOptions };
};


useEffect(()=>{
  let dateTime = timeOptions[playPosition]?timeOptions[playPosition]:timeOptions[playPosition - 1]?timeOptions[playPosition - 1]:timeOptions[timeOptions.length - 1] 
    if(moment().subtract(120,'seconds').utc() < moment.utc(dateTime,"YYYY-MM-DD HH:mm:ss")){
        SetLive(true);
    }else{
        SetLive(false);
    }
},[playPosition,timeOptions])

  const handleChange = (newValue,selectedDate,layerPublish) => {
    SetStartTime(timeOptions[newValue]);
    SetPlayPosition(newValue);
    SetDisplayTime(localTime[newValue]);

    for(let i=0;i<locationCordinate.length;i++){
      space.removeDataLayer(locationCordinate[i].unique_loc_id+(layerPublish -1));
    }
    layerPublish = 0;
    if(moment().subtract(60,'seconds').utc() < moment(getDateFormat(selectedDate)+" "+timeOptions[newValue])){
      // resetTime = 30
      resetTime = 5
      SetLive(true);
    }else{
      resetTime= 5
      SetLive(false);
    }
    SetNextUpdate(resetTime);
  
  };

   function filterEmployee(employeeDefault,departmentName){
    console.log("is it clickedddd",departmentName,employeeDefault);
    SetBtnChange(false);
    SetAutoPlay(false);
    if(departmentName.length > 0 ){
      let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.pleton) > -1 ); 
      console.log("is it clickedddd if ",filterEmp);

      console.log("filterEmp",filterEmp,employeeDefault)
      dataSocket=[];

      SetEmployeeAll(filterEmp);
      
    }else{
      console.log("is it clickedddd else ",employeeDefault);
    SetEmployeeAll(employeeDefault);
    
  }

  setTimeout(function(){
    SetCurrentData([])
    SetAutoPlay(true);
    removeFrame()
  },400)
    
  }

useEffect(() => {

  if(!space || !live || !autoplay){
    return 
  }
  let oldPosition = playPosition;
  let oldTimeOption = [...timeOptions];
  let oldlocalTimeOptions = [...localTime]

  const fetchLiveData = async () => {
    try {
      const res = await getLiveData(userSession, org_id, sub_org_id);

      if(res.status ==200){
       let newData = res.data.map(item=> JSON.parse(item.toString().replace(/'/g, '"') ));
        console.log("live data",newData)
      let data=[];
      for(let i=0;i<newData.length;i++){
        let single = employeeALL.find(el=>newData[i].tag_id == el.tag_serial )
         if(single){
          newData[i].loc_id= newData[i].contact_tag_id;
          newData[i].added_at = newData[i].contact_time;
          newData[i].tag_serial = newData[i].tag_id;
          data.push(newData[i])
        }else{
          
        }
      }
          dataSocket = data;      
          if(data.length > 0){
             
              let originalTime = oldTimeOption[oldTimeOption.length - 1];
              let localTime = oldlocalTimeOptions[oldlocalTimeOptions.length - 1];
              
              
              //utc
              let date = new Date(originalTime);              
              date.setSeconds(date.getSeconds() + 5);
              let updatedTime = moment(date).format('YYYY-MM-DD HH:mm:ss');

              //local
              let localDate = new Date(localTime);
              localDate.setSeconds(localDate.getSeconds() + 5);

              oldPosition = oldPosition + 1;

              oldlocalTimeOptions.push(moment(localDate).format('YYYY-MM-DD HH:mm:ss'));
              oldTimeOption.push(updatedTime);

              SetLocalTime(oldlocalTimeOptions); 
              setTimeOptions(oldTimeOption);
              SetPlayPosition(oldPosition);
          }
          processFacilityMap(data);
        }
      
    } catch (error) {
      console.error("Error fetching live data:", error);
    }
  };

  fetchLiveData();

  const liveupdate = setInterval(function(){fetchLiveData()}, 5000);

  return () => {
    clearInterval(liveupdate);
  };
}, [live, employeeALL,space]);


  return (<>
      {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
      {role? <Col lg={12} className="headerFontSpaceLess"> <CommonHeading title="Facility Tracker" /> </Col>:""}

        <Col lg={6} style={{display:role?'none':'block'}}>
          <CommonHeading title="Facility Map Platoon"/>     
    </Col>
        <Col lg={6} className="text-right" style={{display:role?'none':'block'}}>
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className={role?"":"m-t"} >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}>Filter</h5>
            </div>
        
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Select Platoon"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val)}}
                value={activeDepartment}
                key="departmentFilter"
                style={{width:250}}
                disabled={!space}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
            >
              <Option value='all' key={"Department All"}>All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item} key={"Department"+item}>{item}</Option>
                }):""
                }
            </Select>
            </div>
            <div className='inlineBlockFilter'>
               
            </div>

            <div className='inlineBlockFilter'>
                <button className={'button active btn-productive-filter'} onClick={()=>{SetAutoPlay(false);filterEmployee(employeeDefault,departmentName)}} disabled={!space || !btnChange}>Filter</button>
            </div>
            {space?<>
              {live?<div className={delayTimer?'greenLive disabledClick':'greenLive'} style={{textTransform:"capitalize",border:"0px",fontWeight:"normal",cursor:"not-allowed",pointerEvents:"none"}}>Next Update:  <strong style={{color:"#ef5e8c"}}>{nextUpdate}</strong></div>:""}
              <div className='timerLive' style={{marginBottom:"15px"}}><span className='currentDate'>{moment(selectedDate).format('DD-MM-YYYY')}</span><span className='toptime'>{localTime[playPosition]}</span></div></>:""}
            </div>
        </Col>
      </Row>

      <Row className="m-t">
        <Col lg={9} className='tabletfull' >

        {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""} 
                            <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>



             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''? 
                                
                                <><Viewer onReady={onReady} cameraEnable={true} spaceID={spaceID} changeTab={changeTab} cameraPlacement={cameraPlacement} mode={mode} />
                                          
                                          
                                          {!space?"":
                                          <div className='liveDataPlay' style={{height: "60px",background: "rgba(0, 0, 1, 0.08)",position: "relative"}}>
                                            <div className='playPauseThing' style={{background:autoplay?"#ef5e8c":"grey"}} onClick={()=>{SetAutoPlay(!autoplay);SetStartTime(timeOptions[playPosition])}  } >
                                        {autoplay?<PauseCircle/>:<PlayCircle/>}
                                      </div>
                                    <div className='liveDataRightArea'>
                                    <div className='liveDataPlayInner' style={{ position: 'relative', width: '96%',margin:"0 auto",borderRadius:"20px", height: '20px',top:"10px", backgroundColor:'rgba(71, 59, 59, 0.19)' }}>

                                          {/* <Slider
      min={0}
      max={timeOptions.length - 1}
      step={1}
      value={playPosition}
      onChange={(val)=>handleChange(val,selectedDate,layerPublish)}
      tooltipVisible
      tipFormatter={(index) => displayTime!=""?displayTime:timeOptions[index]}
    /> */}
      <Slider
        min={0}
        max={timeOptions.length - 1}
        step={1}
        value={playPosition}
        onChange={(val) => handleChange(val, selectedDate, layerPublish)}
        tipFormatter={(index) =>  moment(localTime[playPosition]).format('HH:mm:ss')}
        tooltipVisible
        tipProps={{ placement: 'top', visible: true }}
        trackStyle={{ background: 'linear-gradient(to right, #108ee9 0%, #108ee9 ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc 100%)' }}
       
      />
            {/* <TimeSlider date={selectedDate} playPosition={playPosition} timeOptions={timeOptions} changeSlide={onChangePlotTime} SetPlayPosition={()=>SetPlayPosition} /> */}
            {/* {playPosition} */}

</div>
</div>
</div> 
}  


                                
                                </>:<h2>Please Setup Geo Map or contact to administrator</h2>}
          </div>
        </Col>
        <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle" style={{height:'100%',background:"#fff"}}>
            <div className='legendStart'>
              <div className={!space?'legendList legendHeader disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Plan Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Actual Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Worker List</strong></div>
              </div>
              <div className='legendLeftAreaProd'>{ShowCardListLocation()}</div>
              <div className={!space?'legendList  legendHeader legendFooter disable ':'legendList legendHeader'}style={{borderBottom: "0px"}}>
              <div className="customContainer contentGroup contentCategory row" style={{borderBottom: "1px solid #ddd"}}>
                <div className="Category-header group-header">
  <div className="customLeftArea align-middle align-wrapper col-lg-3" style={{marginLeft:0}} ><strong>Total</strong></div>

  <div className="customPlanned align-middle col-lg-2" style={{fontSize:10}}>{isNaN(locationCordinate.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0))?0:locationCordinate.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0)  }</div>
  <div className="customDataLength align-middle col-lg-2" style={{fontSize:10,textAlign:'center'}}>{currentData.length}</div>
  <div className="customRightArea align-middle col-lg-3" style={{marginTop:3,textAlign:"center"}}>
    {dataSocket.length > 0 ? (
      <div className='legendRightAreaProd' onClick={() => categorymodalview1(locationCordinate,playPosition)} key={locationCordinate.tag_serial}>
        <Eye style={{height:13}} />
      </div>
    ) : (
      <div className='legendRightAreaProd disabled nonClickable' key="total-eye-icon">
        <Eye  style={{height:13}}/>
      </div>
    )}
  </div>
  </div>
</div>
</div>
  </div>

          </div>
        </Col>
      </Row>
    </Container>

    <ReactModal
                isOpen={modalView}
                style={customStyles}
                onRequestClose={()=>{SetModalView(false);SetAutoPlay(true)}}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>

  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilityMapPleton