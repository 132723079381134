import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { CommonHeading } from "../../common/commonHeading";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import {
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import '../../report/style/style.scss';

import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import DatePicker from "react-datepicker";
import { Select } from "antd";
import JobCard from "./JobCard";

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};


function ManpowerPlaning(props) {

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();


  const [selectedDate, updateSelectedDate] = useState(date);

  const [dates, setDate] = useState({

    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [isLoading,SetisLoading] = useState(false)
  const [isLoading2,SetisLoading2] = useState(false)
  const [isLoading3,SetisLoading3] = useState(false)
  const [exportEnable,SetExportEnable] =useState(false);
  const [selectedEmployee,SetSelectedEmployee] = useState(null);
  const [selectedJob,SetSelectedJob]= useState(null)


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  const [dataLoad,SetDataLoad] = useState(false);

  const [filterJOB,SetFIlterJOB]= useState(false);
  const [filterPassData,SetFilterPassData]= useState({});

  const isWeekday = (date) => {

        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };

  function handleDateSelect(date) {
    updateSelectedDate(date);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    setDate({ start: endDate, end: endDate });
    SetExportEnable(false);
  }

  

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {

    let perm= props.pages.filter(el=>el.page_name=="Manpower Planning"); 
    
    if(perm){
      

        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

 useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    SetDataLoad(false);
    setDate((prev) => ({ ...prev, [`${type}`]: date }));
  };




function FIlterResultHandlerFOP(value,status){

  SetFilterPassData(value);

}
const generateRandomJobList = (count) => {
  const statuses = ["Active", "Completed", "In Progress", "Over Time","Lapse"];
  const tasks = ["Task A", "Task B", "Task C", "Task D", "Task E"];
  const remarks = ["Remark A", "Remark B", "Remark C", "Remark D", "Remark E"];

  const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];
  const getRandomDate = (start, end) =>
    new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  const jobs = Array.from({ length: count }, (_, index) => {
    const randomStart = getRandomDate(new Date(), new Date(Date.now() + 86400000)); // Random within 24 hours
    const randomEnd = new Date(randomStart.getTime() + Math.random() * 36000000); // Random within 10 hours after start

    return {
      added_at: new Date().toUTCString(),
      job_end: randomEnd.toUTCString(),
      job_id: index + 1,
      job_start: randomStart.toUTCString(),
      location_serial: [
        `02026A7FD2${Math.floor(1000 + Math.random() * 9000)}`,
        `02029${Math.floor(100000 + Math.random() * 900000)}`,
      ],
      org_id: Math.floor(Math.random() * 100) + 1,
      tag_id: `20635f02b${Math.floor(10000 + Math.random() * 90000)}`,
      to_do: Array.from(
        { length: Math.floor(3 + Math.random() * 3) },
        () => getRandomElement(tasks)
      ).join(" | "),
      remark: Array.from(
        { length: Math.floor(2 + Math.random() * 3) },
        () => getRandomElement(remarks)
      ).join(" | "),
      status: getRandomElement(statuses),
    };
  });

  return jobs;
};

const apiData = generateRandomJobList(20);



  return (<>
        {permissionAPICall?
            permission.length > 0?
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            <CommonHeading title="Manpower Planning" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
                workday={props.workday}
              />
            </div>
          </Col>
        </Row>

        <Row className="m-t">
          <Col lg={12}>   
           <div className="manpowerManagementEmployeeListMainDiv">
              <div className="filterField">
                   <Row className="">

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={("Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                    <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={("Date")}
                        maxDate={moment(selectedDate).toDate()}
                        minDate={moment(dates.start).toDate()}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={3} className="p-r-0 report-filter report-filter2">
                      <label>
                        {("Select Employee")}
                      </label>
                      <Select
                      placeholder="Select Employee"
                      showSearch
                      style={{width:"100%"}}
                      value={selectedEmployee}
                      onChange={(val)=>SetSelectedEmployee(val)}
                      >
                        <Select.Option  value="20635f02b100007a">Emp A</Select.Option>
                        <Select.Option  value="20635f02b100007b">Emp B</Select.Option>
                        <Select.Option  value="20635f02b100007c">Emp C</Select.Option>
                        <Select.Option  value="20635f02b100007d">Emp D</Select.Option>
                      </Select>
                  </Col>
                  <Col lg={3} className="p-r-0 report-filter report-filter2">
                      <label>
                        {("Job Name")}
                      </label>
                      <Select
                      placeholder="Select Job Name"
                      showSearch
                      value={selectedJob}
                      style={{width:"100%"}}
                      onChange={(val)=>SetSelectedJob(val)}
                      >
                        <Select.Option  value="Job 1">Job 1</Select.Option>
                        <Select.Option  value="Job 2">Job 2</Select.Option>
                        <Select.Option  value="Job 3">Job 3</Select.Option>
                        <Select.Option  value="Job 4">Job 4</Select.Option>
                      </Select>
                  </Col>

<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter"  disabled={dates.start==''}>Submit</button>
                <button className="btn btn-submit btn-filter btn-export"  disabled={!filterJOB} >Export</button>
                </div>
</Col>


                </Row>
                </div> 
            </div>
          </Col>
        </Row>
        < Row className="m-t">
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv manpowerManagementEmployeeListMainDiv" style={{background:"transparent",padding:0}}>              
                            {isLoading?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                                isLoading2?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>
                                  : 
                                   
                                <React.Fragment>
                                    {isLoading3?
                                    <div className="text-center m-t-lg">
                                      <img src={spinnerLoader} className="m-t-lg" />
                                  </div>
                                      :""}
                              <div className="rportArea">
                            
                            <div className="job-list">
        {apiData.map((job) => (
          <JobCard key={job.job_id} job={job} />
        ))}
      </div>
                               </div>

                                </React.Fragment> 
                            }

                        </div>
                    </Col>
                </Row>
      </Container>


      
    </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ManpowerPlaning)
);
