import React, { useState } from 'react';
import '../style/style.css';
import { Row } from 'react-bootstrap';
import { Col } from 'reactstrap';

const statusColors = {
  Active: "green",
  Completed: "red",
  "In Progress": "orange",
  "Over Time": "blue",
  "Lapse": "grey",
};

const JobCard = ({ job }) => {
  const [toDoList, setToDoList] = useState(job.to_do.split(" | "));
  const [remarks, setRemarks] = useState(job.remark.split(" | "));
  const [newToDo, setNewToDo] = useState("");
  const [newRemark, setNewRemark] = useState("");
  const [isAddingToDo, setIsAddingToDo] = useState(false);
  const [isAddingRemark, setIsAddingRemark] = useState(false);

  const handleAddToDo = () => {
    if (newToDo.trim()) {
      setToDoList([...toDoList, newToDo.trim()]);
      setNewToDo(""); // Reset input
      setIsAddingToDo(false); // Close input field
    }
  };

  const handleAddRemark = () => {
    if (newRemark.trim()) {
      setRemarks([...remarks, newRemark.trim()]);
      setNewRemark(""); // Reset input
      setIsAddingRemark(false); // Close input field
    }
  };

  return (
    <div className='jobListStyle'>
        <Row >
          <Col lg={6}>
          <div className={"job-card "+job.status.toLowerCase().replaceAll(" ","")}  >
      <div className="job-header">
        <Row>
            <Col lg={9}><h5 style={{margin:0}}>{`Inspect Crane ${job.job_id}`}</h5></Col>
            <Col lg={3}><span className="statusJobs" style={{ backgroundColor: statusColors[job.status],borderRadius:10 }}>{job.status}</span></Col>

        </Row>
        
        
      </div>
      <div className="job-content" style={{margin:"30px 0px 15px"}}>
        
            <Col lg={4}><div className='labelHead'>Worker 1</div><><strong>ID:</strong> {job.tag_id}</></Col>
            <Col lg={4}><div className='labelHead'>Start Time</div><p><>{new Date(job.job_start).toLocaleString()}</></p></Col>
            <Col lg={4}><div className='labelHead'>End Time</div><p><>{new Date(job.job_end).toLocaleString()}</></p></Col>
            
       
      </div>
</div>
      
      </Col> 
      <Col lg={2} className='SecondColJob' style={{paddingLeft:0,paddingRight:0}} >
                  {/* To-Do List Section */}
        <div className='job-card'>
          <h5>To-Do List</h5>
          <ul>
            {toDoList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {/* {isAddingToDo ? (
            <div>
              <input
                type="text"
                value={newToDo}
                onChange={(e) => setNewToDo(e.target.value)}
                placeholder="Enter new task"
              />
              <button onClick={handleAddToDo}>Save</button>
              <button onClick={() => setIsAddingToDo(false)}>Cancel</button>
            </div>
          ) : (
            <button className='addTask btn button small-btn btn-pink' onClick={() => setIsAddingToDo(true)}>Add Task</button>
          )} */}
        </div>

      </Col>  
      <Col lg={2} className='ThirdColJob' style={{paddingRight:0}}>
              {/* Remarks Section */}
        <div className='job-card'>
          <h5>Remarks</h5>
          <ul>
            {remarks.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {isAddingRemark ? (
            <div>
              <input
                type="text"
                value={newRemark}
                onChange={(e) => setNewRemark(e.target.value)}
                placeholder="Enter new remark"
              />
              <button onClick={handleAddRemark}>Save</button>
              <button onClick={() => setIsAddingRemark(false)}>Cancel</button>
            </div>
          ) : (
            <button className='addTask btn button small-btn btn-pink' onClick={() => setIsAddingRemark(true)}>Add Remark</button>
          )}
        </div>

      </Col>  
      <Col lg={2} className='ActionJob'>
            <div className="job-actions">
                {job.status === "Active" && <button className="start-btn">Start</button>}
                {job.status !== "Active" && <button className="end-btn">End</button>}
            </div>         
        </Col>  
        </Row>
     </div>
  );
};

export default JobCard;
