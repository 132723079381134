import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Highcharts from 'highcharts';
import ReactHighcharts from "react-highcharts";
import spinnerLoader from "../../../assets/images/loader.svg";
import Scrollbars from "react-custom-scrollbars";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { getReportByPletonHourDetail } from "../../actionMethods/actionMethods";

export default function PletonWorker(props) {
    let date = localStorage.getItem("selectedDate")
        ? new Date(localStorage.getItem("selectedDate"))
        : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    const [selectedDate, updateSelectedDate] = useState(date);
    const [loader, SetLoader] = useState(true);
    const [options, setOptions] = useState({});
    const [activeHour, setActiveHour] = useState(null); // Track active hour
    const [activeEmployees, setActiveEmployees] = useState([]); // Store employee details
    const [filterStillLoading,SetFilterStillLoading] = useState(true);
    const [pletonHourLoader,SetPletonHourLoader] = useState(false);
    const [pletonHourTable,SetPletonHourTable] = useState([]);
    const [pletonHourSelected,SetPletonHourSelected] = useState('');
    const [maxLocation,SetMaxLocation]= useState('');
    const [overallDayAverage,SetOverallDayAverage]= useState('')
   


// {name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric}
    function formatDataClick(name,data,time){

    }

    useEffect(() => {
        async function filterResultHandler(data, pletonName,reportBy) {
            try {
                console.log("filterActive",props.filterActive)
                let startHour;
                if (props.filterActive) {
                    
                    let slug ='hourly_statistics';
                    
                    if(props.pletonType ==="trend" && reportBy==="day"){
                        slug= 'daily_statistics' 
                    }else if(props.pletonType ==="trend" && reportBy==="week"){
                        slug= 'weekly_statistics' 
                    }
                    else if(props.pletonType ==="trend" && reportBy==="month"){
                        slug= 'monthly_statistics' 
                    }
                    
                    const pletonData = data[pletonName][slug];
                    const workerData = data[pletonName]?.workers || [];
                    console.log("pletonData",pletonData,data[pletonName],slug)

                    SetMaxLocation(data[pletonName]?.top_location?.location_name || "")

                    const categories = [];
                    const togatherData = [];
                    const outOfRangeData = [];
                    const notTogatherData = [];

                  
                    Object.keys(pletonData).forEach((timestamp) => {
                        console.log("overall_hourly_average",timestamp)
                        if(timestamp==="overall_hourly_average"){
                            SetOverallDayAverage(pletonData[timestamp].togather);
                        }else{

                        const entry = pletonData[timestamp];
                    
                        let timestampNew = timestamp
                          if(props.pletonType ==="daydetail"){
                               timestampNew =  moment(timestamp).format('hh A')
                            }else if(reportBy ==="day"){
                                timestampNew = moment(timestamp).format('DD MMM');
                            }else if(reportBy ==="week"  || reportBy ==="month"){
                                timestampNew = timestamp.replaceAll("-"," ");
                            }
                            
                        if (typeof entry === 'object') {
                          
                        if(props.pletonType ==="daydetail" && startHour===undefined && (entry.not_togather > 0 || entry.togather >0) ){
                                startHour =timestamp;
                                
                            }
                            categories.push(timestampNew);
                            togatherData.push({
                                y:entry.togather || 0,
                                    employee:workerData,
                                    timestamp:timestampNew,
                                    data:entry.togather_worker,
                                    time:timestamp
                                });
                            outOfRangeData.push({y:entry.out_of_range || 0,employee:workerData,timestamp:timestamp,data:entry.out_of_range_worker,time:timestamp});
                            notTogatherData.push({y:entry.not_togather || 0,employee:workerData,timestamp:timestamp,data:entry.not_togather_worker,time:timestamp});

                        } else {
                            categories.push(moment(timestamp).format('hh A'));
                            togatherData.push({y:0,employee:[],timestamp:timestampNew,data:[],time:timestamp});
                            outOfRangeData.push({y:0,employee:[],timestamp:timestampNew,data:[],time:timestamp});
                            notTogatherData.push({y:0,employee:[],timestamp:timestampNew,data:[],time:timestamp});
                        }
                    }
                    });
                    // Update Highcharts options with click event handler
                    let empFunc= workerData.map(el=>{

                        let func = [...props.employee].find(elm=>elm.worker_id ===el.worker_id)
                        let empRecord= {...el};
                                                console.log("el",el,func);
                        if(func){
                          empRecord.function  = func.sub_department_name;
                          empRecord.check_in  = func.check_in	;
                          empRecord.check_out  = func.check_out	;
                          empRecord.worker_type =func.worker_type;
                        }
                      return empRecord
                    }) 

                    console.log("empRecord",empFunc)
                    setActiveEmployees(empFunc)                    
                    setOptions({
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: ``
                        },
                        xAxis: {
                            categories: categories,
                            crosshair: true,
                            tickPositioner: function () {
                                let positions = [];
                                let tick = 6;
                                positions = [0, 2, 5, 8, 11, 14, 17, 20, 23];
                                return positions;
                            },
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            title: {
                                text: ''
                            }
                        },
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            // column: {
                            //     stacking: 'normal'
                            // },
            column: {
                stacking: 'normal',
            
                states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
                },    
                point: {
                    events: {
                        click: (e) => {
                            e.point.select(true);

                            let obj = {name:e.point.series.name,data:e.point.data,timestamp:e.point.time, time:e.point.category};
                            console.log("obj",obj)
                            SetPletonHourSelected(obj.timestamp);
                            // SetPletonHourLoader(true);
                            // SetPletonHourTable(empFunc.map((el,index)=>({serial:index+1,...el,time:obj.time,deviation:"12 Min 30 Sec" })))                      
                            // setTimeout(function(){
                            //     SetPletonHourLoader(false);
                            // },1000)
                            
                        }
                    }
                },
    
            },


                            
                        },
                        series: [
                            {
                                name: 'Out of Range',
                                data: outOfRangeData,
                                color: "#dedee3",
                                allowPointSelect: true,

                          
                            },
                            {
                                name: 'Not Togather',
                                data: notTogatherData,
                                color: "#fc0001",
                            
                            },
                            {
                                name: 'Togather',
                                data: togatherData,
                                color: "#90cf4e",
                          
                            }
                        ]
                    });
                    SetPletonHourSelected(startHour);
                    SetLoader(false);

                }
            } catch (error) {
                console.log("error", error,data);
            }
        }

        async function filterResultHandlerTrend(data, pletonNames, reportBy) {
        if(pletonNames &&  pletonNames.length>0 && props.filterActive){
            try {
            const categoriesSet = new Set();
            const seriesData = [];
            let allActiveEmp= [];
            let startHour;
              let slug ='daily_statistics';
                    
                    if(props.pletonType ==="trend" && reportBy==="day"){
                        slug= 'daily_statistics' 
                    }else if(props.pletonType ==="trend" && reportBy==="week"){
                        slug= 'weekly_statistics' 
                    }
                    else if(props.pletonType ==="trend" && reportBy==="month"){
                        slug= 'monthly_statistics' 
                    }

            // Iterate over each PletonName
            pletonNames.forEach((pletonName) => {

                const pletonData = data[pletonName]?.[slug] || {}; // Default to hourly stats
                const workerData = data[pletonName]?.workers || [];


                // Temp arrays for this pleton
                const togatherData = [];
                const outOfRangeData = [];
                const notTogatherData = [];

                // Process data for each timestamp
                Object.keys(pletonData).forEach((timestamp) => {
                    const entry = pletonData[timestamp];

                    let timestampFormatted = timestamp
                        let timestampNew = timestamp

                          if(props.pletonType ==="daydetail"){
                               timestampFormatted =  moment(timestamp).format('hh A')
                            }else if(reportBy ==="day"){
                                timestampFormatted = moment(timestamp).format('DD MMM');
                            }else if(reportBy ==="week"  || reportBy ==="month"){
                                timestampFormatted = timestamp.replaceAll("-"," ");
                            }

                    categoriesSet.add(timestampFormatted); // Ensure unique categories


                    if (typeof entry === "object") {
                        togatherData.push({
                            y: entry.togather || 0,
                            timestamp: timestampFormatted,
                            data: entry.togather_worker,
                        });
                        outOfRangeData.push({
                            y: entry.out_of_range || 0,
                            timestamp: timestampFormatted,
                            data: entry.out_of_range_worker,
                        });
                        notTogatherData.push({
                            y: entry.not_togather || 0,
                            timestamp: timestampFormatted,
                            data: entry.not_togather_worker,
                        });
                    } else {
                        togatherData.push({ y: 0 });
                        outOfRangeData.push({ y: 0 });
                        notTogatherData.push({ y: 0 });
                    }
                });

                // Add series for this pleton
                seriesData.push(
                    {
                        name: `Out of Range (${pletonName})`,
                        data: outOfRangeData,
                        stack: pletonName,
                        color: "#dedee3",
                    },
                    {
                        name: `Not Together (${pletonName})`,
                        data: notTogatherData,
                        stack: pletonName,
                        color: "#fc0001",
                    },
                    {
                        name: `Together (${pletonName})`,
                        data: togatherData,
                        stack: pletonName,
                        color: "#90cf4e",
                    }
                );
            });
            setOptions({
                chart: {
                    type: "column",
                },
                title: {
                    text: "",
                },
                xAxis: {
                    categories: Array.from(categoriesSet), // Unique categories
                    crosshair: true,
                },
 tooltip: {
    useHTML: true, // Enable HTML for tooltips
    formatter: function () {
        const hoveredSeriesName = this.series.name.split(" ")[0]; // Get the base category: Out, Together, or Not
        const hoveredCategory = this.x; // Get the date (x-axis category)

        // Filter all series to match the hovered category
        const relevantData = this.series.chart.series
            .filter(series => series.name.startsWith(hoveredSeriesName)) // Match series like "Out of Range"
            .map(series => {
                const point = series.data.find(d => d.category === hoveredCategory);
                return {
                    name: series.name.split("(")[1]?.replace(")", ""), // Extract Pleton name
                    value: point ? point.y : 0,
                };
            });

        // Determine the category name
        let sName =
            hoveredSeriesName === "Out"
                ? "Out of Range"
                : hoveredSeriesName === "Not"
                ? "Not Together"
                : "Together";

        // Build the tooltip content with larger font size
        let tooltipHTML = `<b>${hoveredCategory}</b><br>
            <span style="font-size:14px;"><b> ${sName}</b></span><br>`;
        relevantData.forEach(data => {
            tooltipHTML += `<span style="color:${this.series.color}">\u25CF</span> ${data.name}: <b>${data.value}</b><br>`;
        });

        return tooltipHTML;
    },
    shared: false, // Tooltip only for the hovered series
},
legend:{
    enabled:false
},


                yAxis: {
                    min: 0,
                    max:100,
                    title: {
                        text: "",
                    },
                },

                plotOptions: {
                    column: {
                        stacking: "normal",
                        
                    },
                },
                series: seriesData, // Dynamically generated series
            });

            SetLoader(false);
        } catch (error) {
            console.error("Error in filterResultHandlerTrend:", error);
        }
        }
        
    }


        if(props.pletonType==="daydetail"){
            filterResultHandler(props.filterPassData.data, props.filterPassData.name,props.filterPassData.reportBy);
        }else{
            filterResultHandlerTrend(props.filterPassData.data, props.filterPassData.name,props.filterPassData.reportBy);
        }
        

    }, [props.filterPassData, props.filterFOP]);

function convertToArray (data,activeEmployees) {


   console.log("convertToArray in filter map",activeEmployees);     
  return Object.entries(data).map(([groupName, employees]) => ({
    name: groupName,
    employees: Object.entries(employees).map(([employeeName, details]) =>{
        console.log("activeEmployees in loop",activeEmployees,details)
        return({
            name: employeeName,
            ...details,
            // // max_time_location_name:props.location.find(el=>el.loc_tag	===details.max_time_location)?.name,	
            // tag_serial:activeEmployees.find(el=>el.name=== employeeName)?.tag_serial,	
            // worker_id:activeEmployees.find(el=>el.name=== employeeName)?.worker_id,		
            // worker_type:activeEmployees.find(el=>el.name=== employeeName)?.worker_type
        })
  }
),
  }));
};
    useEffect(()=>{
        SetPletonHourLoader(true);
  
        if(pletonHourSelected!="" && activeEmployees.length > 0 && props.pletonType==="daydetail"){
        getReportByPletonHourDetail(userSession,org_id,sub_org_id,props.filterPassData.start_date,props.filterPassData.end_date,moment(pletonHourSelected).format("HH"),props.filterPassData.name).then(res=>{
            

            if(res.status==200){
                let formatedData =convertToArray(res.data,activeEmployees).find(el=>el.name===props.filterPassData.name)?.employees || [];
                    console.log("formatedData",formatedData)
                    SetPletonHourTable(formatedData);
                }else{
                    SetPletonHourTable([]);
                }
            SetPletonHourLoader(false);
            

        })
        }


    },[pletonHourSelected,activeEmployees])

function capitalizeFirstLetter(string) {
    if(string){
      return string.toLowerCase();
    }
  }
function AGTable(data){
    console.log("Ag Table data",data);
    let key = data.length +pletonHourSelected;
    let arr;
    let arr2=[];
   
    if(data){  
          

  


    arr =<div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
    <AgGridReact
            rowHeight={35}
            copyHeadersToClipboard= {true}
            enableRangeSelection= {true}   
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
            headerHeight={35}
         defaultColDef={{sortable: true,resizable: true,minWidth:45, flex:1  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         pagination= {data.length>500}
         paginationPageSize= {500}             
            rowData={data}
            onCellClicked={(params)=>{
                console.log("params",params.data);
                let slug= params.data.worker_type && (params.data.worker_type=="employee" || params.data.worker_type=="regular")?"employee":"daily_worker"
                if(params.data.worker_id){
                    window.open(`/productivity/${slug}/:${params.data.worker_id}/${moment(props.filterPassData.start_date).format('YYYY-MM-DD')}`)
                }
                
            }}
         >    
        <AgGridColumn
            field="name"
            headerName={"Name"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
            key={"name"}
        />
      
      
    <AgGridColumn
      field="tag_serial"
      headerName={"Tag Serial"}
      key={"tag_serial"}
    />
      <AgGridColumn
          field="worker_id"
          headerName={"Emp ID"}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      />

      {/* <AgGridColumn
          field="max_time_location_name"
          headerName={"Max Time Location"}
          valueFormatter={(params)=>params.value}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      />  */}
            {/* <AgGridColumn
          field="max_time_spent"
          headerName={"Max Time Spend"}
          valueFormatter={(params)=>params.value}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      />  */}
   <AgGridColumn
          field="togather"
          headerName={"Together"}
          valueFormatter={(params)=>params.value}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      /> 
         <AgGridColumn
          field="not_togather"
          headerName={"Not Together"}
          valueFormatter={(params)=>params.value}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      /> 
         <AgGridColumn
          field="out_of_range"
          headerName={"Out of Range"}
          valueFormatter={(params)=>params.value}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      /> 
 
    </AgGridReact>
    </div></div>
    
      
            }
        return arr;    


  }

    return (
        props.filterActive ?
            loader ?
                <div className="text-center m-t-lg">
                    <img src={spinnerLoader} className="m-t-lg" alt="Loading" />
                </div> :
                <div className="App">
                    <ToastContainer />
                
                    {props.pletonType ==="daydetail"?
                    <><Row style={{borderBottom:"1px solid #ddd",marginBottom:30}}>
                        <Col lg="4">
                                <h4>Platoon:  <span style={{ color: "#ef5e8c" }}>{props.filterPassData.name}</span>
                                </h4>
                        </Col>

                        <Col lg={4}>{maxLocation  && maxLocation!=""?<h6 style={{fontSize:14,textAlign:"right",lineHeight:"40px"}}>Max Time Spent Location <strong style={{ background: "#ef5e8c",padding:5,color:"#fff",marginLeft:10,borderRadius:5,fontWeight:"normal"}}>{maxLocation}</strong></h6>:""}</Col>
                                                <Col lg={4}><h6 style={{fontSize:14,textAlign:"right",lineHeight:"40px"}}>Overall Together <strong style={{ background: "#ef5e8c",padding:5,color:"#fff",marginLeft:10,borderRadius:5,fontWeight:"normal" }}>{overallDayAverage!=""?overallDayAverage+"%":"-"}</strong></h6></Col>
                        {/* <Col lg={6}></Col> */}
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <ReactHighcharts
                                config={options}
                                options={options}
                            />
                        </Col>
                        <Col lg={5}>
                            <h5>Employee Details</h5>
                            {/* <table className="tableStyle2">
                              <thead>
                                 <tr>
                                 <th>Name</th>
                                 <th>Check In</th>
                                 <th>Check Out</th>
                                 <th>Function</th>
                                 </tr>
                              </thead>
                              </table>
                                  <Scrollbars style={{height:300,width:"100%"}}>
                                    <table className="tableStyle2" > 
                              <tbody>
                                    {activeEmployees.map((employee, index) => (
                                        <tr key={index} className="">
                                            <td>{employee.name}</td>
                                            <td>{moment(employee.check_in.replaceAll(" GMT","")).format('DD MMM hh:mm A')}</td>
                                            <td>{employee.check_out?moment(employee.check_out.replaceAll(" GMT","")).format('DD MMM hh:mm A'):"-"}</td>
                                            <td>{employee.function}</td>
                                        </tr>

                                    ))}

                            </tbody>

                            </table>
                                                                </Scrollbars> */}
                                                                <div style={{ width: "100%" }}>
                        <table className="tableStyle2">
                            <thead>
                            <tr>
                                <th style={{ width: "25%" }}>Name</th>
                                <th style={{ width: "25%" }}>Check In</th>
                                <th style={{ width: "25%" }}>Check Out</th>
                                <th style={{ width: "25%" }}>Function</th>
                            </tr>
                            </thead>
                        </table>
                        <Scrollbars style={{ height: 300, width: "100%" }}>
                            <table className="tableStyle2">
                            <tbody>
                                {activeEmployees.map((employee, index) => (
                                <tr key={index}>
                                    <td style={{ width: "25%" }}>{employee.name}</td>
                                    <td style={{ width: "25%" }}>
                                    {moment(employee.check_in.replaceAll(" GMT", "")).format('DD MMM hh:mm A')}
                                    </td>
                                    <td style={{ width: "25%" }}>
                                    {employee.check_out
                                        ? moment(employee.check_out.replaceAll(" GMT", "")).format('DD MMM hh:mm A')
                                        : "-"}
                                    </td>
                                    <td style={{ width: "25%" }}>{employee.function}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </Scrollbars>
                        </div>


                        </Col>
                        <Col lg={12}>
                        {pletonHourLoader?<div style={{textAlign:"center"}}><img src={spinnerLoader} /></div>:pletonHourSelected?<><h4>Platoon Hour Detail <span style={{ color: "#ef5e8c" }}>{moment(pletonHourSelected).format('hh A')}</span></h4>{AGTable(pletonHourTable)}</>:null}
                        </Col>
                    </Row></>:
                    <><Row>
                        
                        <Col lg="12"><h4>Platoon:  {props.filterPassData.name.map(el=>{ return <span key={el} style={{fontSize:14,fontWeight:"normal",lineHeight:"40px", border: "1px solid #ef5e8c",color:"#ef5e8c",padding:"5px 15px",borderRadius:10,marginLeft:10 }}>{el}</span>})}</h4></Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ReactHighcharts
                                config={options}
                                options={options}
                            />
                        </Col>
                    </Row></>
                    
                    }



                </div> : ""
    );
}
