import React, { useState, useCallback,useReducer,useEffect,useMemo } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../../common/commonHeading";
import Viewer from '../Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, type
} from 'ramda'
import {
  getLanguageTranslation
} from "../../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../../components/dashboardLanguage";
import CommonDatePicker from "../../../common/commonDatePicker";
import {ReactComponent as Image404 } from '../../../assets/images/11132-ai.svg'
import spinnerLoader from "../../../assets/images/Spinner Loader.gif";
import '../../style/style.scss'
import PolygonData from './polygonData.json'
import chroma from 'chroma-js';
import { A, G, D, pipe } from '@mobily/ts-belt';
import { boundingBox2d, computeGrid, isPointInPolygon } from './utils';
import { ground,ground2,categoryPolyline } from './ground';
import DefaultIcon from  "../../../assets/images/heatmap.png"
import { getLocationCordinate,getStreamData,getCategoryList } from '../../actionMethods/actionMethods';
import moment from 'moment';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


const FacilityVideo = (props) => {

  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ]);
  let prefixURL = process.env.REACT_APP_URL;

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )  
  const [space,setSpace] = useState()

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
//   let GeoWidth= userDetails && parseInt(userDetails.org_id) === 31?1.3:4;
//   let GeoElevation= userDetails && parseInt(userDetails.org_id) === 31?3:5;
  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:(userDetails && parseInt(userDetails.org_id) == 35) || (userDetails && parseInt(userDetails.org_id) == 38)?1: (userDetails && parseInt(userDetails.org_id) == 37)?0.4:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34) || (userDetails && parseInt(userDetails.org_id) == 35)|| (userDetails && parseInt(userDetails.org_id) == 38)?3:(userDetails && parseInt(userDetails.org_id) == 37)?1:5;

  let userSession = userDetails ? userDetails.session:props.match.params.session?props.match.params.session:'';
  let org_id = userDetails ? userDetails.org_id : props.match.params.org_id?props.match.params.org_id:'';
  let sub_org_id = userDetails ? userDetails.sub_org_id : props.match.params.sub_org_id?props.match.params.sub_org_id:0;
  const role = props.match.params.role?props.match.params.role:null;
  
//   const [spaceID]=useState('76576eec-688c-49e9-b0af-23af5811d0cc');
  const [spaceID,SetSpaceID]=useState('59a74b5c-374a-4ef2-a5c3-63f3aa2b220f');
  const [apiCall,SetAPICall]=useState(false)
  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [inc,SetInc]=useState(0)
  const [alpha, setAlpha] = useState(0.5);
  const [gridGap, setGridGap] = useState(0.1);
  const [viewerReady, setViewerReady] = useState(false);
  const [view, setView] = useState('grid');
  const [gridSize, setGridSize] = useState(2.5);
  const [diameter, setDiameter] = useState(0.25);
  const [pointViz, setPointViz] = useState('color');
  const [pointHeight, setPointHeight] = useState(3);
  const [gridUseColor, setGridUseColor] = useState(true);
  const [gridUseBars, setGridUseBars] = useState(false);
  const [gridIsFlat, setGridIsFlat] = useState(false);
  const [gridHeight, setGridHeight] = useState(3);
  const [locationCordinate,SetLocationCordinate]= useState([])
  const [catLocation,SetCatLocation]= useState([]);
  const [catPolyLine,SetCatPolyLine]=  useState([]);
  const [eventData, setEventData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const bb = useMemo(() => boundingBox2d(ground2), []);
  const [timestamp,SetTimestamp]= useState([]);
  const [points, setPoints] = useState([{id: Date.now(),percentage:100,timestamp:moment().format('hh:mm:ss A')}]);
  const [firstLoad,SetFirstLoad]= useState(true);
  const [playPosition,SetPlayPosition]= useState(0);    


   const grid = useMemo(() => {
    if (G.isNullable(bb)) {
      return
    }

    const gridCompute=computeGrid({
        bb,
        gridSize,
        gridGap,
        elevation: pointHeight,
      });
     const grid ={} 
     
    for(let i=0;i<categoryPolyline.length;i++){ 
      grid[categoryPolyline[i].name]= A.filter(gridCompute, (cell) => A.all(cell.coordinates, (corner) => isPointInPolygon(corner, categoryPolyline[i].coordinates)));
    }
   
  return grid
  }, [bb, gridSize, gridGap, pointHeight]);
  

  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }


    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
  

    useEffect(() => {
      
      let pagePerm= props.pages.filter(el=>el.page_name=="Geo Map Management")
      SetPermission(pagePerm);
      SetPermissionAPICall(true)
    },[props.pages]);
  


    function handleDateSelect(date) {
    updateSelectedDate(date);
  }

  const onReady = useCallback(space => setSpace(space), [])
  // const hmData = useMemo(
  //   () =>
  //     A.map(grid, (cell) => {
  //       const distance = Math.sqrt(
  //         Math.pow(cell.position.x - 42, 2) + Math.pow(cell.position.z + 18, 2)
  //       );
  //       return D.merge(cell, { value: (distance * (1 + Math.random())) / 76 });
  //     }),
  //   [grid]
  // );
function getHeatmapColor(value) {
    // Ensure the value is within the range [0, 100]
    value = Math.max(0, Math.min(100, value));

    // Interpolate between light grey (#CCCCCC) and light red (#FFCCCC)
    const greyHex = Math.floor(0xCC + (0xFF - 0xCC) * (value / 100));
    const colorCode = `#${greyHex.toString(16).padStart(2, '0')}CC${'CC'}`;

    return colorCode;
}


  
useEffect(() => {
    
    if(permission.facilities=='False'){
      return
    }
    
    if (!space) {
      return
    }
    
    if(catLocation.length ===0){
      return
    }
    

    const eventSource = new EventSource(`${prefixURL}/stream_data?session=${userDetails.session}&org_id=${userDetails.org_id}`);
    
    eventSource.onmessage = (event) => {
      const newData = JSON.parse(event.data.toString().replace(/'/g, '"') );

      setEventData((prevData) => [...prevData, newData]);
    };
    eventSource.onerror = (error) => {
     
    }
    space.addDataLayer({
            id: 'heatmap1',
            type: 'polygon',
            data: [],
            alpha:0.9,
    });
    return () => {
     
      eventSource.close();
    };
  }, [space,permission,catLocation]);

  

useEffect(() => {
    const intervalId = setTimeout(() => {
      addPoint();
    }, 30000);

    
    return () => {
      clearInterval(intervalId);
    };
  }, [points]);
useEffect(() => {
    if(!firstLoad || !space || catLocation.length==0){
      return
    }
  const intervalId = setTimeout(() => {
      heatmapPlotting(firstLoad);
      
      SetFirstLoad(false);
    }, 5000);
    

    return () => {
      clearInterval(intervalId);
    };
  }, [firstLoad,space,catLocation]);

  const addPoint = () => {
    const now = new Date();
    const totalSeconds = now.getUTCHours() * 3600 + now.getUTCMinutes() * 60 + now.getUTCSeconds();
    const percentage = (totalSeconds / (24 * 3600)) * 100;
    let timestamp=moment().format('hh:mm:ss A')
      setPoints(prevPoints => [...prevPoints, {id: Date.now(),percentage:percentage,timestamp:timestamp}]);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      heatmapPlotting(false);
     }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventData,catLocation,grid]);

  function heatmapPlotting(firstLoad,point=timestamp.length){
      // Display accumulated data every 30 seconds
      setDisplayedData((prevData) => [...prevData, ...eventData]);
      let catNew =[];
      
      if(catLocation.length==0){
        return
      }
      let evt =[
	{
		"org_id": 28,
		"tag_id": "01C39C013E6CD5",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 9.500643249329354,
		"z": 5.134889134203081,
		"added_at": "2023-12-14T07:51:11.084Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01C5C38EAE4582",
		"loc_id": "021097BD15909C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 92.77315972105072,
		"z": -13.503964654690247,
		"added_at": "2023-12-14T07:51:11.239Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01C6E7D199A50F",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 73.78853122964573,
		"z": -33.621179524850334,
		"added_at": "2023-12-14T07:51:11.284Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01C8703429F5C1",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 15.933930696594427,
		"z": 17.797114187355717,
		"added_at": "2023-12-14T07:51:11.315Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01C99366519954",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 2.995181197771421,
		"z": 21.973961786188877,
		"added_at": "2023-12-14T07:51:11.348Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01CCB89054219C",
		"loc_id": "021097BD147760",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 59.67120948715287,
		"z": 11.65792308392041,
		"added_at": "2023-12-14T07:51:11.384Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01CF6828CFC9CE",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 61.1348438291343,
		"z": 11.268178265761748,
		"added_at": "2023-12-14T07:51:11.426Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01CFE57DEAE98A",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 2.2560322842260305,
		"z": -1.2720200295670523,
		"added_at": "2023-12-14T07:51:11.472Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01D106CC3A1137",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 0.7826128877407237,
		"z": 3.352448054700849,
		"added_at": "2023-12-14T07:51:11.508Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01D589696F0E71",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 4.717064335040744,
		"z": 28.37445463278101,
		"added_at": "2023-12-14T07:51:11.543Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01D72582FEBE65",
		"loc_id": "021097BD14464C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": -7.894561080144307,
		"z": -30.263039316632103,
		"added_at": "2023-12-14T07:51:11.559Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01D756A5F80968",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 3.1701406181649077,
		"z": 0.8244822696776706,
		"added_at": "2023-12-14T07:51:11.591Z",
		"rssi": 70
	},
	{
		"org_id": 28,
		"tag_id": "01D84935F7FEBF",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 2.2560322842260305,
		"z": -1.2720200295670523,
		"added_at": "2023-12-14T07:51:11.626Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01DC09C099E7E8",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 50.357506423748816,
		"z": -1.8113928290800203,
		"added_at": "2023-12-14T07:51:11.688Z",
		"rssi": 78
	},
	{
		"org_id": 28,
		"tag_id": "01DCD5784B3FDA",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 73.65286264721529,
		"z": -46.96269284925708,
		"added_at": "2023-12-14T07:51:11.840Z",
		"rssi": 78
	},
	{
		"org_id": 28,
		"tag_id": "01DCE173901958",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 1.0491464283057508,
		"z": 30.13995754344515,
		"added_at": "2023-12-14T07:51:11.892Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01DD3A328B0B50",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 1.0529358800021382,
		"z": 29.89427177429014,
		"added_at": "2023-12-14T07:51:11.968Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01DD7869283D74",
		"loc_id": "021097BD14464C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 6.874316641128146,
		"z": -5.978550371103701,
		"added_at": "2023-12-14T07:51:12.038Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01DE5574B6B0D7",
		"loc_id": "021097BD15909C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 85.25699247195102,
		"z": -26.624970116956536,
		"added_at": "2023-12-14T07:51:12.132Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01DF216D2987CA",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 0.2964175412512695,
		"z": 14.131485085175791,
		"added_at": "2023-12-14T07:51:12.171Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01E1803A9A0D6C",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 2.2560322842260305,
		"z": -1.2720200295670523,
		"added_at": "2023-12-14T07:51:12.210Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01E5066164C766",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 50.054741760107326,
		"z": -13.080640289045004,
		"added_at": "2023-12-14T07:51:12.300Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01E5E390B3C431",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -7.082467750916267,
		"z": 18.42220757058537,
		"added_at": "2023-12-14T07:51:12.358Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01E6A33693FEA6",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -3.1314259568198377,
		"z": 13.039841065806906,
		"added_at": "2023-12-14T07:51:12.448Z",
		"rssi": 70
	},
	{
		"org_id": 28,
		"tag_id": "01E73DBE7761B4",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 55.39473863626262,
		"z": 11.31251195252874,
		"added_at": "2023-12-14T07:51:12.572Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01E867BEF9DEFB",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": -16.364100850494967,
		"z": -1.0779943753961945,
		"added_at": "2023-12-14T07:51:12.661Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01E8D903DBAF09",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -5.347992228698964,
		"z": 16.7845506233094,
		"added_at": "2023-12-14T07:51:12.709Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01F0DB82FA3C9E",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": -2.684720026852269,
		"z": 4.165211649536628,
		"added_at": "2023-12-14T07:51:12.796Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01F1908F49887E",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 0.06381228782465076,
		"z": 4.631239721177444,
		"added_at": "2023-12-14T07:51:12.875Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01F22412805D66",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -1.2849878729853392,
		"z": 7.030840397063772,
		"added_at": "2023-12-14T07:51:12.948Z",
		"rssi": 68
	},
	{
		"org_id": 28,
		"tag_id": "01F2DDCD48E8D0",
		"loc_id": "021097BD159F38",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 0.2964175412512695,
		"z": 14.131485085175791,
		"added_at": "2023-12-14T07:51:13.028Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01F6920D9EE84F",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -2.493969705721861,
		"z": 0.3117919778934839,
		"added_at": "2023-12-14T07:51:13.086Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01F7EB8CF51A59",
		"loc_id": "021097BD157F8C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 16.177297938486863,
		"z": -25.30883090166128,
		"added_at": "2023-12-14T07:51:13.188Z",
		"rssi": 85
	},
	{
		"org_id": 28,
		"tag_id": "01F8DD393851F3",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 38.90122060149476,
		"z": -47.91325979837906,
		"added_at": "2023-12-14T07:51:13.250Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01FC71BB33BC8A",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 38.98930614700467,
		"z": -31.997863778829426,
		"added_at": "2023-12-14T07:51:13.332Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01FCBBC7CF71FA",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 10.671146409640466,
		"z": 30.65085334266728,
		"added_at": "2023-12-14T07:51:13.438Z",
		"rssi": 67
	},
	{
		"org_id": 28,
		"tag_id": "01FCFCA7870EBF",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": -5.9220226253616035,
		"z": 33.768681037668266,
		"added_at": "2023-12-14T07:51:13.547Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01FD543C654C5A",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 2.997520493944595,
		"z": 21.76534245188021,
		"added_at": "2023-12-14T07:51:13.619Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01FEF8C437AD36",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 60.96094832244674,
		"z": -43.10282748022249,
		"added_at": "2023-12-14T07:51:13.669Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01C0EE52D3FE52",
		"loc_id": "021097BD157F8C",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 16.177297938486863,
		"z": -25.30883090166128,
		"added_at": "2023-12-14T07:55:00.423Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01D935C854174C",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 22.670087167898338,
		"z": 26.822669517369444,
		"added_at": "2023-12-14T07:55:05.889Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01E0A6F7E7B70D",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 0.013122746592376444,
		"z": 3.660554533415505,
		"added_at": "2023-12-14T07:55:09.078Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01E211BFDFE268",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 62.693309143130314,
		"z": -10.619906098297282,
		"added_at": "2023-12-14T07:55:09.750Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01EF4796BBD27D",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 3,
		"x": 25.50362403859695,
		"z": 2.052344624139945,
		"added_at": "2023-12-14T07:55:12.251Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01FEC389B5F948",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:51:00.000Z",
		"productivity": 1,
		"x": 0.013122746592376444,
		"z": 3.660554533415505,
		"added_at": "2023-12-14T07:55:16.622Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01C0EE52D3FE52",
		"loc_id": "021097BD157F8C",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 16.177297938486863,
		"z": -25.30883090166128,
		"added_at": "2023-12-14T07:50:41.952Z",
		"rssi": 84
	},
	{
		"org_id": 28,
		"tag_id": "01C39C013E6CD5",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 3.0684150326196584,
		"z": 17.579117647110618,
		"added_at": "2023-12-14T07:50:42.060Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01C5C38EAE4582",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 34.340393032044936,
		"z": 29.28547084268676,
		"added_at": "2023-12-14T07:50:42.099Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01C6E7D199A50F",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 4.953875057670675,
		"z": -1.776677145688176,
		"added_at": "2023-12-14T07:50:42.120Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01C8703429F5C1",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 65.31954626228912,
		"z": -18.822141975443444,
		"added_at": "2023-12-14T07:50:42.164Z",
		"rssi": 77
	},
	{
		"org_id": 28,
		"tag_id": "01C99366519954",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 65.18422043696843,
		"z": -14.666870195758284,
		"added_at": "2023-12-14T07:50:42.195Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01CCB89054219C",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 3.113250926102523,
		"z": 2.2526244125922315,
		"added_at": "2023-12-14T07:50:42.228Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01CF6828CFC9CE",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 57.21137744967346,
		"z": 22.03346931346568,
		"added_at": "2023-12-14T07:50:42.259Z",
		"rssi": 68
	},
	{
		"org_id": 28,
		"tag_id": "01CFE57DEAE98A",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 15.282894385585518,
		"z": -4.406783042817419,
		"added_at": "2023-12-14T07:50:42.292Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01D589696F0E71",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 50.59178860283638,
		"z": -1.5809012766101116,
		"added_at": "2023-12-14T07:50:42.332Z",
		"rssi": 81
	},
	{
		"org_id": 28,
		"tag_id": "01D72582FEBE65",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 68.79636799574165,
		"z": -41.46504302101804,
		"added_at": "2023-12-14T07:50:42.375Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01D756A5F80968",
		"loc_id": "021097BD144A68",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 7.962402363323984,
		"z": 10.201221041942254,
		"added_at": "2023-12-14T07:50:42.459Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01D84935F7FEBF",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 32.352269488262046,
		"z": 6.224016425658371,
		"added_at": "2023-12-14T07:50:42.487Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01D935C854174C",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 22.670087167898338,
		"z": 26.822669517369444,
		"added_at": "2023-12-14T07:50:42.522Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01D9A417466A02",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 2.997520493944595,
		"z": 21.76534245188021,
		"added_at": "2023-12-14T07:50:42.543Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01DC09C099E7E8",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 59.71710886935551,
		"z": -2.9285547064295203,
		"added_at": "2023-12-14T07:50:42.563Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01DCD5784B3FDA",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": -1.454781193488246,
		"z": 22.498855137638067,
		"added_at": "2023-12-14T07:50:42.583Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01DCE173901958",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 28.674028022941673,
		"z": 26.59775396660846,
		"added_at": "2023-12-14T07:50:42.619Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01DD3A328B0B50",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 5.627934594147075,
		"z": -2.083181293571076,
		"added_at": "2023-12-14T07:50:42.643Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01DD7869283D74",
		"loc_id": "021097BD157944",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 41.02299419902064,
		"z": -1.657545847472493,
		"added_at": "2023-12-14T07:50:42.670Z",
		"rssi": 80
	},
	{
		"org_id": 28,
		"tag_id": "01DE5574B6B0D7",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 8.413595664770478,
		"z": -4.6769246954213095,
		"added_at": "2023-12-14T07:50:42.704Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01DF216D2987CA",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 9.99671874562461,
		"z": -3.876047803407572,
		"added_at": "2023-12-14T07:50:42.744Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01E0A6F7E7B70D",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 0.013122746592376444,
		"z": 3.660554533415505,
		"added_at": "2023-12-14T07:50:42.782Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01E1803A9A0D6C",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 66.33402379047695,
		"z": -40.39488338740651,
		"added_at": "2023-12-14T07:50:42.812Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01E211BFDFE268",
		"loc_id": "021097BD143558",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 62.693309143130314,
		"z": -10.619906098297282,
		"added_at": "2023-12-14T07:50:42.823Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01E5066164C766",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 45.395215842033345,
		"z": -5.677149445595621,
		"added_at": "2023-12-14T07:50:42.924Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01E5E390B3C431",
		"loc_id": "021097BD147760",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 49.018226513111074,
		"z": -19.36894710605246,
		"added_at": "2023-12-14T07:50:43.184Z",
		"rssi": 80
	},
	{
		"org_id": 28,
		"tag_id": "01E6A33693FEA6",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 9.236050951885861,
		"z": -2.5227717548012905,
		"added_at": "2023-12-14T07:50:43.228Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01E73DBE7761B4",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 57.75693277530076,
		"z": 21.505028917263076,
		"added_at": "2023-12-14T07:50:43.264Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01E867BEF9DEFB",
		"loc_id": "021097BD147790",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 7.544860694072228,
		"z": -38.0529470990095,
		"added_at": "2023-12-14T07:50:43.303Z",
		"rssi": 67
	},
	{
		"org_id": 28,
		"tag_id": "01E8D903DBAF09",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 17.26157509359898,
		"z": 10.272268941020531,
		"added_at": "2023-12-14T07:50:43.327Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01EF4796BBD27D",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 25.50362403859695,
		"z": 2.052344624139945,
		"added_at": "2023-12-14T07:50:43.391Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01F0DB82FA3C9E",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 2.2560322842260305,
		"z": -1.2720200295670523,
		"added_at": "2023-12-14T07:50:43.420Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01F1908F49887E",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 8.445510053375097,
		"z": -5.478094145971829,
		"added_at": "2023-12-14T07:50:43.444Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01F22412805D66",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 10.42111202536655,
		"z": 14.308070795935345,
		"added_at": "2023-12-14T07:50:43.472Z",
		"rssi": 67
	},
	{
		"org_id": 28,
		"tag_id": "01F2DDCD48E8D0",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": -0.6457923404931111,
		"z": 5.893671173365892,
		"added_at": "2023-12-14T07:50:43.503Z",
		"rssi": 70
	},
	{
		"org_id": 28,
		"tag_id": "01F6920D9EE84F",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": -1.136270117830135,
		"z": 6.766262117674763,
		"added_at": "2023-12-14T07:50:43.527Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01F7EB8CF51A59",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 50.357506423748816,
		"z": -1.8113928290800203,
		"added_at": "2023-12-14T07:50:43.551Z",
		"rssi": 80
	},
	{
		"org_id": 28,
		"tag_id": "01F8DD393851F3",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 49.35860463653313,
		"z": -14.20267312714829,
		"added_at": "2023-12-14T07:50:43.592Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01FC71BB33BC8A",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 27.536493926326795,
		"z": -2.7897082894962484,
		"added_at": "2023-12-14T07:50:43.620Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01FCBBC7CF71FA",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 64.86910435723219,
		"z": -2.3261545236102013,
		"added_at": "2023-12-14T07:50:43.635Z",
		"rssi": 66
	},
	{
		"org_id": 28,
		"tag_id": "01FCFCA7870EBF",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 9.801964811814988,
		"z": -3.529568254561402,
		"added_at": "2023-12-14T07:50:43.667Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01FD543C654C5A",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 27.639396975826607,
		"z": 20.038464230014874,
		"added_at": "2023-12-14T07:50:43.700Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01FEF8C437AD36",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 5.627934594147075,
		"z": -2.083181293571076,
		"added_at": "2023-12-14T07:50:43.735Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01D106CC3A1137",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 3,
		"x": 3.0972627239690707,
		"z": 2.653987549365425,
		"added_at": "2023-12-14T07:55:04.083Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01FEC389B5F948",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:30.000Z",
		"productivity": 1,
		"x": 0.013122746592376444,
		"z": 3.660554533415505,
		"added_at": "2023-12-14T07:55:16.622Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01C39C013E6CD5",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 27.57476962890793,
		"z": 15.85239848743465,
		"added_at": "2023-12-14T07:50:12.883Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01C5C38EAE4582",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 64.8693541654845,
		"z": -2.153497817719492,
		"added_at": "2023-12-14T07:50:13.016Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01C6E7D199A50F",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 61.786133061057726,
		"z": -10.76971803423847,
		"added_at": "2023-12-14T07:50:13.228Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01C8703429F5C1",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 64.86997401461461,
		"z": -1.725084793059457,
		"added_at": "2023-12-14T07:50:13.440Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01C99366519954",
		"loc_id": "02E831CD8FF758",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 28.624699764033156,
		"z": -21.363065835140855,
		"added_at": "2023-12-14T07:50:13.513Z",
		"rssi": 74
	},
	{
		"org_id": 28,
		"tag_id": "01CCB89054219C",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 60.95527258661221,
		"z": -26.470671998696762,
		"added_at": "2023-12-14T07:50:13.567Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01CF6828CFC9CE",
		"loc_id": "021097BD147760",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 66.14706855263366,
		"z": 3.886746083591196,
		"added_at": "2023-12-14T07:50:13.659Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01CFE57DEAE98A",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 56.94685806746195,
		"z": -9.233518836298195,
		"added_at": "2023-12-14T07:50:13.726Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01D106CC3A1137",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 3.0972627239690707,
		"z": 2.653987549365425,
		"added_at": "2023-12-14T07:50:13.807Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01D589696F0E71",
		"loc_id": "021097BD147760",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 61.98180283381399,
		"z": 1.678739860571623,
		"added_at": "2023-12-14T07:50:13.888Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01D756A5F80968",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 17.35754642402538,
		"z": 10.424255770365669,
		"added_at": "2023-12-14T07:50:13.963Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01D84935F7FEBF",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 32.35516470279616,
		"z": 5.603225670740512,
		"added_at": "2023-12-14T07:50:14.010Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01D935C854174C",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 52.95320918122778,
		"z": -38.471327321756405,
		"added_at": "2023-12-14T07:50:14.071Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01D9A417466A02",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 53.39183608147332,
		"z": 5.39242298731949,
		"added_at": "2023-12-14T07:50:14.145Z",
		"rssi": 80
	},
	{
		"org_id": 28,
		"tag_id": "01DC09C099E7E8",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 42.38031040284636,
		"z": 13.387914861415405,
		"added_at": "2023-12-14T07:50:14.219Z",
		"rssi": 77
	},
	{
		"org_id": 28,
		"tag_id": "01DCD5784B3FDA",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 50.357506423748816,
		"z": -1.8113928290800203,
		"added_at": "2023-12-14T07:50:14.291Z",
		"rssi": 81
	},
	{
		"org_id": 28,
		"tag_id": "01DCE173901958",
		"loc_id": "021097BD1579B8",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 84.12997420554606,
		"z": -20.85646791198557,
		"added_at": "2023-12-14T07:50:14.380Z",
		"rssi": 87
	},
	{
		"org_id": 28,
		"tag_id": "01DD3A328B0B50",
		"loc_id": "021097BD147790",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 22.93923870604717,
		"z": -10.130510144080173,
		"added_at": "2023-12-14T07:50:14.461Z",
		"rssi": 77
	},
	{
		"org_id": 28,
		"tag_id": "01DD7869283D74",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": -11.847804604027996,
		"z": 24.548030604078868,
		"added_at": "2023-12-14T07:50:14.520Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01DE5574B6B0D7",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 47.64561460041108,
		"z": 1.9371703416871018,
		"added_at": "2023-12-14T07:50:14.604Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01DF216D2987CA",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 3.0778938258459823,
		"z": 16.62596743268516,
		"added_at": "2023-12-14T07:50:14.728Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01E1803A9A0D6C",
		"loc_id": "021097BD15B0FC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 50.357506423748816,
		"z": -1.8113928290800203,
		"added_at": "2023-12-14T07:50:14.900Z",
		"rssi": 77
	},
	{
		"org_id": 28,
		"tag_id": "01E211BFDFE268",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 2.9994815592453037,
		"z": 21.590453892551416,
		"added_at": "2023-12-14T07:50:15.012Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01E5066164C766",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 53.30686092108576,
		"z": -18.44900520258885,
		"added_at": "2023-12-14T07:50:15.052Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01E5E390B3C431",
		"loc_id": "021097BD143008",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 27.465389197394767,
		"z": 20.230581498992994,
		"added_at": "2023-12-14T07:50:15.200Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01E6A33693FEA6",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": -11.979964415604586,
		"z": -7.084570982518808,
		"added_at": "2023-12-14T07:50:15.448Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01E73DBE7761B4",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 2.2560322842260305,
		"z": -1.2720200295670523,
		"added_at": "2023-12-14T07:50:15.544Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01E867BEF9DEFB",
		"loc_id": "021097BD147790",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 7.537740347411758,
		"z": -37.8030694147413,
		"added_at": "2023-12-14T07:50:15.644Z",
		"rssi": 69
	},
	{
		"org_id": 28,
		"tag_id": "01E8D903DBAF09",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 0.9017262240132968,
		"z": 34.46106093146328,
		"added_at": "2023-12-14T07:50:15.785Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01EF4796BBD27D",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 6.324313858872053,
		"z": -2.3998345290606435,
		"added_at": "2023-12-14T07:50:15.840Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01F0DB82FA3C9E",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 43.777634788139096,
		"z": -22.355661674051248,
		"added_at": "2023-12-14T07:50:15.905Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01F1908F49887E",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 4.643810605925269,
		"z": -1.635686572262275,
		"added_at": "2023-12-14T07:50:15.984Z",
		"rssi": 71
	},
	{
		"org_id": 28,
		"tag_id": "01F22412805D66",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 8.380426787747501,
		"z": -3.8442629355770666,
		"added_at": "2023-12-14T07:50:16.087Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01F6920D9EE84F",
		"loc_id": "021097BD143CBC",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 8.363467112081103,
		"z": -3.4185122121808744,
		"added_at": "2023-12-14T07:50:16.116Z",
		"rssi": 72
	},
	{
		"org_id": 28,
		"tag_id": "01F7EB8CF51A59",
		"loc_id": "021097BD157F8C",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 16.177297938486863,
		"z": -25.30883090166128,
		"added_at": "2023-12-14T07:50:16.153Z",
		"rssi": 85
	},
	{
		"org_id": 28,
		"tag_id": "01F8DD393851F3",
		"loc_id": "021097BD14464C",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 8.619280043698044,
		"z": -25.49264448337193,
		"added_at": "2023-12-14T07:50:16.171Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01FC71BB33BC8A",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 5.047623432767072,
		"z": -1.8193058220429887,
		"added_at": "2023-12-14T07:50:16.197Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01FCBBC7CF71FA",
		"loc_id": "021097BD159CD0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 58.52462139667231,
		"z": 6.280886813191534,
		"added_at": "2023-12-14T07:50:16.235Z",
		"rssi": 66
	},
	{
		"org_id": 28,
		"tag_id": "01FCFCA7870EBF",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": -1.2330500955615213,
		"z": 6.938439810652673,
		"added_at": "2023-12-14T07:50:16.263Z",
		"rssi": 67
	},
	{
		"org_id": 28,
		"tag_id": "01FD543C654C5A",
		"loc_id": "021097BD15A934",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 2.9904901420362244,
		"z": 22.392311944627316,
		"added_at": "2023-12-14T07:50:16.283Z",
		"rssi": 73
	},
	{
		"org_id": 28,
		"tag_id": "01FEC389B5F948",
		"loc_id": "021097BD1573C0",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 0.013122746592376444,
		"z": 3.660554533415505,
		"added_at": "2023-12-14T07:50:16.314Z",
		"rssi": 75
	},
	{
		"org_id": 28,
		"tag_id": "01FEF8C437AD36",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 39.21674842721466,
		"z": -31.45320652740796,
		"added_at": "2023-12-14T07:50:16.344Z",
		"rssi": 76
	},
	{
		"org_id": 28,
		"tag_id": "01C0EE52D3FE52",
		"loc_id": "021097BD147790",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 7.555359034032626,
		"z": -38.421370295667685,
		"added_at": "2023-12-14T07:55:00.423Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01D72582FEBE65",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": 56.09625539248118,
		"z": -12.68543987580927,
		"added_at": "2023-12-14T07:55:04.815Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01E0A6F7E7B70D",
		"loc_id": "021097BD143940",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 1,
		"x": 55.759494837335396,
		"z": -43.4465708842933,
		"added_at": "2023-12-14T07:55:09.078Z",
		"rssi": null
	},
	{
		"org_id": 28,
		"tag_id": "01F2DDCD48E8D0",
		"loc_id": "021097BD158488",
		"contact_time": "2023-12-14T07:50:00.000Z",
		"productivity": 3,
		"x": -22.826043264077093,
		"z": 5.993187750746009,
		"added_at": "2023-12-14T07:55:13.583Z",
		"rssi": null
	}
]

      for(let i=0;i<categoryPolyline.length;i++){
        
        let pertLoc = catLocation.find(el=>el.name.trim() === categoryPolyline[i].name.trim())
        let valPer = evt.filter(el=>pertLoc.location.indexOf(el.loc_id) > -1);
        let single= categoryPolyline[i];
        single.value= (valPer.length/300)*100
      
        catNew.push(single); 
      }
      let catPoly=[]
      Object.keys(grid).forEach((key) => {

         let singleVal =catNew.find(el=>el.name.trim() === key.trim())?.value;
        //  let singleVal =Math.random() * 100;
        
         let cord = grid[key]

         for(let j=0;j<cord.length;j++){
          if(key !=="all"){
          let single=cord[j];
              single.value= singleVal;
             catPoly.push(single)
          }

         }        
      })
      const colorScale = chroma.scale('RdYlBu').padding([0, -1]);

      space.addDataLayer({
        id: 'heatmap'+timestamp.length+1,
        type: 'polygon',
        data: catPoly,
        alpha:0.9,
        // color: (d) => d.color,
        color:(d)=>  colorScale(d.value/100).hex(),
        ...(gridUseBars
          ? {
              height: (d) => Math.pow(1 - d.value, 2) * 10,
            }
          : {
              baseHeight: gridIsFlat ? gridHeight : 0,
              height: gridIsFlat ? 0.01 : gridHeight,
            }),
      })
     
      space.removeDataLayer('heatmap'+timestamp.length);
      SetPlayPosition(point);

      SetTimestamp((prevData) => [...prevData, {time:moment().format('DD-MM-YYYY hh:mm:ss') ,data:catNew}])
      if(!firstLoad){
        setEventData([]); // Clear accumulated data
      }

  }

  useEffect(() => {
    if (!space || G.isNullable(grid)) {
      return;
    }
    
    getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){

        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }
        SetLocationCordinate(res.data.location);       
        }
      });
    
      let requestBody={}
      requestBody.org_id=userDetails.org_id;
      requestBody.session=  userDetails.session;

      getCategoryList(requestBody).then(res=>{
        if(res.status ===200){
          let arr=[]
          
          let resCat = res.data.filter(el=>el.location.length > 0);
          for(let i=0;i<resCat.length;i++){
            let single={}
            single.name=resCat[i].name;
            single.location=resCat[i].location.map(el=>el.tag_serial);
            arr.push(single);
          }
          SetCatLocation(arr);          
        }
      })



    return () => {
      space.removeDataLayer('heatmap');
    };
  }, [
    space,
    view,
    grid,
    diameter,
    pointViz,
    alpha,
    gridUseColor,
    gridUseBars,
    gridIsFlat,
    gridHeight,

  ]);






    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);
    
  
  


    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }


 function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'point',
      data: [{
        position: data.position, 
      }],
      diameter:0.25,
      color:'#000',
      anchor: 'top',
      tooltip: d => data.name,
      alpha:0.5
    })
  }
}

const handleMouseEnter = (id) => {
    // Add a class to the hovered element
    const element = document.getElementById(`point-${id}`);
    if (element) {
      element.classList.add('hovered-point');
    }
  };

  const handleMouseLeave = (id) => {
    // Remove the class when the mouse leaves the element
    const element = document.getElementById(`point-${id}`);
    if (element) {
      element.classList.remove('hovered-point');
    }
  };

  
  

      
  return (<>
      {permissionAPICall?permission.length> 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
      {role? <Col lg={12} className="headerFontSpaceLess"> <CommonHeading title="Facility Tracker" /> </Col>:""}

        <Col lg={6} style={{display:role?'none':'block'}}>

                 <div className='tabHeader'>
              
                <div className='tabHeaderList' onClick={()=>props.history.push('/employee-tracker')}>
                <CommonHeading title="Employee Tracker"/>
                </div>   
                <div className='tabHeaderList active'>
                <CommonHeading title="Facility Tracker" />
                </div>
                <div className='tabHeaderList ' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>
           </div>   
    </Col>
        <Col lg={6} className="text-right" style={{display:role?'none':'block'}}>
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>


      <Row className="m-t">
        <Col lg={1}></Col>
        <Col lg={10} className='tabletfull' >

        {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""}

                  
                   
                            <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>



             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''?
                                <>
                                  <Viewer onReady={onReady} inc={inc} spaceID={spaceID} changeTab={()=>console.log("hello")} /*cameraPlacement={cameraPlacements}*//>
                                  
                                  <div className='liveDataPlay' style={{height: "40px",background: "rgba(0, 0, 1, 0.08)",position: "relative"}}>
                                    <div style={{ position: 'relative', width: '96%',margin:"0 auto",borderRadius:"20px", height: '20px',top:"10px", backgroundColor:'rgba(71, 59, 59, 0.19)' }}>
                                      <div className='highlightTillPlay' style={{width:`96%`,}}></div>

      {points.map((point, index) => (
        <div
          key={index}
          className="slider-point"
          onMouseEnter={() => handleMouseEnter(point.id)}
          onMouseLeave={() => handleMouseLeave(point.id)}
         
          id={`point-${point.id}`}
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            left:index===0?'95%':`${index/points.length*95}%`,
            cursor:"pointer"
          }}>
          <div className='tooltipTime'>{point.timestamp}</div>
        </div>
      ))}
    </div>
                                </div>
                                </>:<h2>Please Setup Geo Map or contact to administrator</h2>}
          </div>
        </Col>
      </Row>
    </Container>


  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilityVideo