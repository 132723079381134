import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Checkbox, Select, Spin } from 'antd';
import {getCategoryList} from '../../../realtimeTracker/actionMethods/actionMethods';
import {getReportByPleton} from  '../../actionMethods/actionMethods';
// import DummyData from './dummyAll.json';
import { toast, ToastContainer } from "react-toastify";


export default function PletonWorkerFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : moment().subtract(1,'day').toDate();
    date = date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():date
    
    const [dates, setDate] = useState({
        start:null,
        end: null,
    });
    const [inc,SetInc]= useState(0)
    const [reportBy,SetReportBy]= useState('day');
    const [isLoading, setIsLoading] = useState(true); // Assuming your state for loader
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [pletonList,SetPLetonList]= useState([]);
    const [pletonSelected,SetPletonSelected]= useState('');
    const [pletonSelectedMultiple,SetPletonSelectedMultiple]= useState([]);
    const [pletonData,SetPletonData]= useState({})
    const [firstLoad,SetFirstLoad] = useState(true);
    const [pletonType,SetPletonType] = useState('daydetail');



    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    



    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

       function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

      useEffect(()=>{
        
        if(dates.start!=null){
          console.log("dates.start",dates.start);
        
        let requestBody ={}
        requestBody.session =userSession;
        requestBody.org_id= org_id

        props.pletonLoadOn()
        
        // props.filterResultHandler({},true);
        SetPLetonList([]);
        SetPletonData({});
        SetPletonSelected('');

        if(props.pletonType && props.pletonType==="daydetail"){
          SetInc((prev)=>prev+1);
          
          getReportByPleton(userSession,org_id,getDateFormat(dates.start),getDateFormat(dates.start)).then(res=>{
              if(res.status == 200){
                let pl = [];
                console.log("res",res);
                Object.keys(res.data).forEach((key)=>{
                    console.log("key",key,res.data[key]);

                    res.data[key].togather = GetRandomDigit(0,100);
                    res.data[key].max_location_spend = "Inbound"
                    Object.keys(res.data[key].hourly_statistics).forEach((key2)=>{


                      // res.data[key].hourly_statistics[key2]['not_togather_worker'] = res.data[key].workers.map(el=>({...el,deviation_time:"12 min 30 Sec"}))
                      // res.data[key].hourly_statistics[key2]['out_of_range_worker'] = res.data[key].workers.map(el=>({...el,deviation_time:"12 min 30 Sec"}))
                      // res.data[key].hourly_statistics[key2]['togather_worker'] = res.data[key].workers.map(el=>({...el,deviation_time:"12 min 30 Sec"}))                  
                    }) 

                    pl.push(key);
                })
                // SetInc((prev)=>prev+1);
                SetPLetonList(pl);
                SetPletonData(res.data);
                console.log("res.data",res.data);
                props.pletonLoaddOff()
              }
              if(res.status==302||res.status==201){
                console.log("loader is it off");
                // SetInc((prev)=>prev+1);
                SetPLetonList([]);
                SetPletonData({});
                console.log("res.data",res.data);
                toast.error(res.message?res.message:"Something Wrong While Fetching Platoon Detail");
                props.pletonLoaddOff()
              }
        });

        }else{
          SetInc((prev)=>prev+1);
          getReportByPleton(userSession,org_id,getDateFormat(dates.start),getDateFormat(dates.end),reportBy).then(res=>{
 
            if(res.status == 200){
                let pl = [];
                console.log("res",res);
                let slug= 'hourly_statistics'
                if(reportBy==="day"){
                 slug= 'daily_statistics' 
                }else if(reportBy==="week"){
                  slug= 'weekly_statistics' 
                }
                else if(reportBy==="day"){
                  slug= 'monthly_statistics' 
                }

                Object.keys(res.data).forEach((key)=>{
                    pl.push(key);
                })
      
                SetPLetonList(pl);
                SetPletonData(res.data);
                console.log("res.data",res.data);
                props.pletonLoaddOff()
              }
              if(res.status==302  || res.status==201){
               
                // SetInc((prev)=>prev+1);
                SetPLetonList([]);
                SetPletonData({});
                console.log("res.data",res.data);
                props.pletonLoaddOff()
                // toast.error("Something Wrong While Fetching Platoon Detail");           
                toast.error(res.message?res.message:"Something Wrong While Fetching Platoon Detail")
              }
        });

        }
      }
      },[dates.start,dates.end,reportBy,props.pletonType])

      useEffect(()=>{
        props.filterResultHandler({},false);
      },[reportBy])
      

      

      useEffect(()=>{
        if(inc > 1){
          console.log("when hits reload");
          props.dataReloadOnboardPlanActual({start:dates.start,end:dates.start});  
        }
      },[inc])

    useEffect(()=>{
      setDate((prev)=>({end:props.date,start:props.date}));
      
    },[props.date])

        const marks= {
            1: '1',
            10: '10',
            20: '20',
            30: '30',
            40: '40',
            50: '50',
            60: '60',
            70: '70',
            80: '80',
            90: '90',
            100: '100',
          };

    function filterResultHandler(){
        
       
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.start).format('YYYY-MM-DD') ,
          name:props.pletonType=="daydetail"?pletonSelected:pletonSelectedMultiple,
          data: pletonData,
          reportBy:reportBy
        }
        console.log("requestBody",props.pletonType,requestBody,reportBy)
        props.pletonLoadOn()
        props.filterResultHandler(requestBody,true);
        setTimeout(function(){
          props.pletonLoaddOff()
        },200);
        
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])


//     useEffect(()=>{

// let date = props.date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():props.date
      
      

//       setDate((prev)=>({end:date,start:date}) )
//     },[props.date])

    function onChangePletonSelect(val){
      SetPletonSelected(val);
}

    
    return (
      <>

      <Row className="">
        <ToastContainer/>
                  <Col lg={props.pletonType==="trend"?2:3} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                {props.pletonType==="trend"? <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        value={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={moment(selectedDate).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>:""}

                   
                  <Col lg={props.pletonType==="trend"?4:6} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Select Platoon")}
                      </label>
                      {props.pletonType==="trend"?
                        <Select
                          onChange={(e)=>{SetPletonSelectedMultiple(e);props.filterResultHandler({},false);}}
                          style={{width:"100%"}}
                           value={pletonSelectedMultiple}
                           disabled={pletonList.length===0}
                           dropdownStyle={{ maxHeight: '800px', overflowY: 'auto' }}
                           mode="multiple"
                          
                        >{pletonList.map(el=>{
                            return  <Select.Option value={el}>{el}</Select.Option>
                          })}
                        </Select>:<Select
                          onChange={(e)=>{onChangePletonSelect(e);props.filterResultHandler({},false);}}
                          style={{width:"100%"}}
                           value={pletonSelected}
                           disabled={pletonList.length===0}
                           dropdownStyle={{ maxHeight: '800px', overflowY: 'auto' }}
                          //  mode="multiple"
                          
                        >{pletonList.map(el=>{
                            return  <Select.Option value={el}>{el}</Select.Option>
                          })}
                        </Select>}
                  </Col>
{props.pletonType==="trend"?<Col lg={3} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
                      <div className="ant-checkbox-group">
                        <Checkbox defaultChecked={true}  checked={reportBy ==="day"} onChange={(e)=>{props.filterResultHandler({},false);SetReportBy('day');}}>Daily</Checkbox>
                        <Checkbox defaultChecked={false} checked={reportBy ==="week"} onChange={(e)=>{props.filterResultHandler({},false);SetReportBy('week');}}>Weekly</Checkbox>
                        <Checkbox defaultChecked={false} checked={reportBy ==="month"} onChange={(e)=>{props.filterResultHandler({},false);SetReportBy('month');}}>Monthly</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>:""}


<Col lg={props.pletonType==="trend"?1:2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter"
                 onClick={()=>filterResultHandler()} disabled={
                  dates.start==''|| (props.pletonType==="daydetail" && pletonSelected==="") ||
                   (props.pletonType==="trend" && pletonSelectedMultiple.length===0

                   )}>Submit</button>

                {/* <button className="btn btn-submit btn-hfilter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button> */}
                </div>
</Col>


                </Row>
                </>
                )

}